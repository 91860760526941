.ig-login {
    // background-image: url("assets/img/construction-bg.jpeg");
    background: url("../../img/construction-bg.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    background-color: var(--surface-ground);
    color: var(--text-color);
    height: 100%;
    width: 100%;
}

.surface-card {
    width: 450px;
}

@media screen and (max-width: 640px) {
    .surface-card {
        width: 90%;
    }
}