$color_1: #a4252c;
$color_2: #605e5c;
$color_3: #323130;
$color_primary: var(--primary-color);
$color_primary_text: #ffffff;
$color_6: #005a9e;
$color_7: #d45c00;
$color_8: #00b7c3;
$color_9: #498205;
$color_10: #ffaa44;
$color_11: #8378de;
$color_12: #d13438;
$color_13: #b4aba3;
$color_14: inherit;
$color_15: #107c10;
$color_16: #797775;
$color_17: #a80000;
$color_18: #faf9f8;
$color_19: #f8f9fa;
$color_20: #a19f9d;
$font-family_1: var(--font-family);
$font-family_2: "PrimeIcons";
$background-color_1: rgba(0, 0, 0, 0.4);
$background-color_2: #faf9f8;
$background-color_3: transparent;
$background-color_primary: var(--primary-color);
$background-color_5: #ffffff;
$background-color_6: #c8c6c4;
$background-color_7: #a19f9d;
$background-color_8: rgba(0, 0, 0, 0.5);
$background-color_9: rgba(255, 255, 255, 0.1);
$background-color_10: #edebe9;
$background-color_11: #498205;
$background-color_12: #00b7c3;
$background-color_13: #ffaa44;
$background-color_14: #d13438;
$background-color_15: #d45c00;
$background-color_16: #deecf9;
$background-color_17: #323130;
$background-color_18: #f3f2f1;
$border-color_1: #323130;
$border-color_2: var(--primary-color);
$border-color_3: #a4252c;
$border-color_4: transparent;
$border-color_5: #005a9e;
$border-color_6: #ffffff;
$border-color_7: #605e5c;
$border-color_8: #106ebe;
$border-color_9: #bf5300;
$border-color_10: #aa4a00;
$border-color_11: #00a5b0;
$border-color_12: #00929c;
$border-color_13: #427505;
$border-color_14: #3a6804;
$border-color_15: #ff9b24;
$border-color_16: #ff8d03;
$border-color_17: #6a5dd7;
$border-color_18: #5141d1;
$border-color_19: #c02b2f;
$border-color_20: #aa272a;
$border-color_21: #a19f9d;
$border-color_22: #edebe9;
$border-color_23: transparent transparent transparent transparent;
$border-color_24: rgba(255, 255, 255, 0);
$border-color_25: #eeeeee;
$border-color_26: #f3f2f1;
$border-top-color_1: #ffffff;
$border-right-color_1: #ffffff;
$border-bottom-color_1: #ffffff;
$border-bottom-color_2: #f2f2f2;
$border-left-color_1: #ffffff;
$outline-color_1: #605e5c;

$borderRadius : 2px;
$boxShadowPrev : inset 0 0 0 0.15rem #605e5c;
$boxShadow : none;


@keyframes p-component-overlay-enter-animation {
	from {
		background-color: transparent;
	}
	to {
		background-color: var(--maskbg);
	}
}
@keyframes p-component-overlay-leave-animation {
	from {
		background-color: var(--maskbg);
	}
	to {
		background-color: transparent;
	}
}

* {
	box-sizing: border-box;
}
.p-component {
	font-family: $font-family_1;
	font-size: 1rem;
	font-weight: normal;
	&:disabled {
		opacity: 0.6;
	}
	.p-menu-separator {
		border-color: $border-color_25;
	}
}
.p-component-overlay {
	background-color: $background-color_1;
	transition-duration: 0.2s;
}
.p-disabled {
	opacity: 0.6;
}
.p-error {
	color: $color_1;
}
.p-text-secondary {
	color: $color_2;
}
.pi {
	font-size: 1rem;
}
.p-link {
	font-size: 1rem;
	font-family: $font-family_1;
	border-radius: $borderRadius;
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
	}
}
.p-component-overlay-enter {
	animation: p-component-overlay-enter-animation 150ms forwards;
}
.p-component-overlay-leave {
	animation: p-component-overlay-leave-animation 150ms forwards;
}
.p-autocomplete {
	.p-autocomplete-loader {
		right: 0.5rem;
	}
	.p-autocomplete-multiple-container {
		padding: 0.25rem 0.5rem;
		&:not(.p-disabled) {
			&:hover {
				border-color: $border-color_1;
			}
		}
		&:not(.p-disabled).p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
			border-color: $border-color_2;
		}
		.p-autocomplete-input-token {
			padding: 0.25rem 0;
			input {
				font-family: $font-family_1;
				font-size: 1rem;
				color: $color_3;
				padding: 0;
				margin: 0;
			}
		}
		.p-autocomplete-token {
			padding: 0.25rem 0.5rem;
			margin-right: 0.5rem;
			background: $background-color_10;
			color: $color_3;
			border-radius: $borderRadius;
			.p-autocomplete-token-icon {
				margin-left: 0.5rem;
			}
		}
	}
}
.p-autocomplete.p-autocomplete-dd {
	.p-autocomplete-loader {
		right: 2.857rem;
	}
}
.p-autocomplete.p-invalid.p-component {
	>.p-inputtext {
		border-color: $border-color_3;
	}
}
.p-autocomplete-panel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	.p-autocomplete-items {
		padding: 0;
		.p-autocomplete-item {
			margin: 0;
			padding: 0.75rem 0.5rem;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:hover {
				color: $color_3;
				background: #f3f2f1;
			}
		}
		.p-autocomplete-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
		.p-autocomplete-item-group {
			margin: 0;
			padding: 0.75rem 0.5rem;
			color: $color_primary;
			background: #ffffff;
			font-weight: 600;
		}
	}
}
.p-calendar.p-invalid.p-component {
	>.p-inputtext {
		border-color: $border-color_3;
	}
}
.p-datepicker {
	padding: 0.75rem;
	background: #ffffff;
	color: $color_3;
	border: 1px solid #605e5c;
	border-radius: $borderRadius;
	&:not(.p-datepicker-inline) {
		background: #ffffff;
		border: 0 none;
		box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
		.p-datepicker-header {
			background: #ffffff;
		}
	}
	.p-datepicker-header {
		padding: 0 0.5rem 0.5rem 0.5rem;
		color: $color_3;
		background: #ffffff;
		font-weight: 600;
		margin: 0;
		border-bottom: 0 none;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-datepicker-prev {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-datepicker-next {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-datepicker-title {
			line-height: 2rem;
			order: 1;
			margin: 0 auto 0 0;
			.p-datepicker-year {
				color: $color_3;
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				font-weight: 600;
				padding: 0.5rem;
				&:enabled {
					&:hover {
						color: $color_primary;
					}
				}
			}
			.p-datepicker-month {
				color: $color_3;
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				font-weight: 600;
				padding: 0.5rem;
				margin-right: 0.5rem;
				&:enabled {
					&:hover {
						color: $color_primary;
					}
				}
			}
		}
	}
	table {
		font-size: 1rem;
		margin: 0;
		font-size: 90%;
		th {
			padding: 0.5rem;
			>span {
				width: 2rem;
				height: 2rem;
			}
		}
		td {
			padding: 1px;
			>span {
				width: 2rem;
				height: 2rem;
				border-radius: $borderRadius;
				transition: box-shadow 0.2s;
				border: 0 none;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
			>span.p-highlight {
				color: $color_3;
				background: $background-color_10;
			}
		}
		td.p-datepicker-today {
			>span {
				background: $background-color_primary;
				color: $color_primary_text;
				border-color: $border-color_4;
				border-radius: 50%;
			}
			>span.p-highlight {
				color: $color_3;
				background: $background-color_10;
			}
		}
	}
	.p-datepicker-buttonbar {
		padding: 1rem 0;
		border-top: 1px solid $border-color_22;
		border-top: 0 none;
		.p-button {
			width: auto;
			font-size: 90%;
			color: $color_3;
			.p-button-label {
				font-weight: 400;
			}
			&:enabled {
				&:hover {
					color: $color_3;
					background-color: $background-color_18;
				}
			}
		}
	}
	.p-timepicker {
		border-top: 1px solid $border-color_22;
		padding: 0.5rem;
		border-top: 0 none;
		button {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
			&:last-child {
				margin-top: 0.2em;
			}
		}
		span {
			font-size: 1.25rem;
			font-size: 90%;
		}
		>div {
			padding: 0 0.5rem;
		}
		.pi-chevron-up {
			&:before {
				content: "\e91c";
			}
		}
		.pi-chevron-down {
			&:before {
				content: "\e919";
			}
		}
	}
	.p-monthpicker {
		margin: 0;
		.p-monthpicker-month {
			padding: 1px;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			padding: 0.5rem 0;
		}
		.p-monthpicker-month.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
	}
	.p-yearpicker {
		margin: 0;
		.p-yearpicker-year {
			padding: 1px;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
		}
		.p-yearpicker-year.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
	}
	&:not(.p-disabled) {
		table {
			td {
				span {
					&:not(.p-highlight) {
						&:not(.p-disabled) {
							&:hover {
								background: #f3f2f1;
							}
							&:focus {
								outline: 0 none;
								outline-offset: 0;
								box-shadow: $boxShadow;
							}
						}
					}
				}
			}
		}
		.p-monthpicker {
			.p-monthpicker-month {
				&:not(.p-disabled) {
					&:not(.p-highlight) {
						&:hover {
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
			}
		}
		.p-yearpicker {
			.p-yearpicker-year {
				&:not(.p-disabled) {
					&:not(.p-highlight) {
						&:hover {
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
			}
		}
	}
	.p-datepicker-prev {
		order: 2;
		.p-datepicker-prev-icon {
			&:before {
				content: "\e919";
			}
		}
	}
	.p-datepicker-next {
		order: 3;
		font-size: 90%;
		.p-datepicker-next-icon {
			&:before {
				content: "\e91c";
			}
		}
	}
}
.p-datepicker.p-datepicker-timeonly {
	.p-timepicker {
		border-top: 0 none;
	}
}
.p-datepicker.p-datepicker-multiple-month {
	.p-datepicker-group {
		border-left: 1px solid $border-color_22;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		padding-top: 0;
		padding-bottom: 0;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			border-left: 0 none;
		}
	}
}
.p-cascadeselect {
	background: #ffffff;
	border: 1px solid #605e5c;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: $borderRadius;
	&:not(.p-disabled) {
		&:hover {
			border-color: $border-color_1;
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
		border-color: $border-color_2;
	}
	.p-cascadeselect-label {
		background: transparent;
		border: 0 none;
		padding: 0.5rem 0.5rem;
		&:enabled {
			&:focus {
				outline: 0 none;
				box-shadow: none;
			}
		}
	}
	.p-cascadeselect-label.p-placeholder {
		color: $color_2;
	}
	.p-cascadeselect-trigger {
		background: transparent;
		color: $color_2;
		width: 2.357rem;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
}
.p-cascadeselect.p-invalid.p-component {
	border-color: $border-color_3;
}
.p-cascadeselect-panel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	.p-cascadeselect-items {
		padding: 0;
		.p-cascadeselect-item {
			margin: 0;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			.p-cascadeselect-item-content {
				padding: 0.75rem 0.5rem;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: $color_3;
						background: #f3f2f1;
					}
				}
			}
			.p-cascadeselect-group-icon {
				font-size: 0.875rem;
			}
		}
		.p-cascadeselect-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
	}
}
.p-input-filled {
	.p-cascadeselect {
		background: #faf9f8;
		&:not(.p-disabled) {
			&:hover {
				background-color: $background-color_2;
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: $background-color_2;
		}
	}
	.p-checkbox {
		.p-checkbox-box {
			background-color: $background-color_2;
		}
		.p-checkbox-box.p-highlight {
			background: $background-color_primary;
		}
		&:not(.p-checkbox-disabled) {
			.p-checkbox-box {
				&:hover {
					background-color: $background-color_2;
				}
			}
			.p-checkbox-box.p-highlight {
				&:hover {
					background: #005a9e;
				}
			}
		}
	}
	.p-dropdown {
		background: #faf9f8;
		&:not(.p-disabled) {
			&:hover {
				background-color: $background-color_2;
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: $background-color_2;
			.p-inputtext {
				background-color: $background-color_3;
			}
		}
	}
	.p-inputtext {
		background-color: $background-color_2;
		&:enabled {
			&:hover {
				background-color: $background-color_2;
			}
			&:focus {
				background-color: $background-color_2;
			}
		}
	}
	.p-multiselect {
		background: #faf9f8;
		&:not(.p-disabled) {
			&:hover {
				background-color: $background-color_2;
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: $background-color_2;
		}
	}
	.p-radiobutton {
		.p-radiobutton-box {
			background-color: $background-color_2;
			&:not(.p-disabled) {
				&:hover {
					background-color: $background-color_2;
				}
			}
		}
		.p-radiobutton-box.p-highlight {
			background: #ffffff;
			&:not(.p-disabled) {
				&:hover {
					background: #ffffff;
				}
			}
		}
	}
	.p-treeselect {
		background: #faf9f8;
		&:not(.p-disabled) {
			&:hover {
				background-color: $background-color_2;
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: $background-color_2;
		}
	}
}
.p-checkbox {
	width: 20px;
	height: 20px;
	.p-checkbox-box {
		border: 1px solid #605e5c;
		background: #ffffff;
		width: 20px;
		height: 20px;
		color: $color_3;
		border-radius: $borderRadius;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-checkbox-icon {
			transition-duration: 0.2s;
			color: $color_primary_text;
			font-size: 14px;
		}
		&:not(.p-disabled).p-focus {
			box-shadow: inset 0 0 0 1px $background-color_primary;
		}
	}
	.p-checkbox-box.p-highlight {
		border-color: $border-color_2;
		background: $background-color_primary;
		&:not(.p-disabled) {
			&:hover {
				border-color: $border-color_5;
				background: #005a9e;
				color: $color_primary_text;
			}
		}
	}
	&:not(.p-checkbox-disabled) {
		.p-checkbox-box {
			&:hover {
				border-color: $border-color_1;
			}
		}
		.p-checkbox-box.p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
			border-color: $border-color_2;
		}
		.p-checkbox-box.p-highlight {
			&:hover {
				border-color: $border-color_5;
				background: #005a9e;
				color: $color_primary_text;
			}
		}
	}
	.p-checkbox-box.p-disabled {
		background-color: $background-color_18;
		border-color: $border-color_26;
		color: $color_20;
		opacity: 1;
		user-select: none;
	}
}
.p-checkbox.p-invalid {
	>.p-checkbox-box {
		border-color: $border-color_3;
	}
}
.p-chips {
	.p-chips-multiple-container {
		padding: 0.25rem 0.5rem;
		&:not(.p-disabled) {
			&:hover {
				border-color: $border-color_1;
			}
		}
		&:not(.p-disabled).p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
			border-color: $border-color_2;
			box-shadow: inset 0 0 0 1px $background-color_primary;
		}
		.p-chips-token {
			padding: 0.25rem 0.5rem;
			margin-right: 0.5rem;
			background: $background-color_10;
			color: $color_3;
			border-radius: $borderRadius;
			.p-chips-token-icon {
				margin-left: 0.5rem;
			}
		}
		.p-chips-input-token {
			padding: 0.25rem 0;
			input {
				font-family: $font-family_1;
				font-size: 1rem;
				color: $color_3;
				padding: 0;
				margin: 0;
			}
		}
	}
	.p-chips-multiple-container.p-disabled {
		background-color: $background-color_18;
		border-color: $border-color_26;
		color: $color_20;
		opacity: 1;
		user-select: none;
	}
}
.p-chips.p-invalid.p-component {
	>.p-inputtext {
		border-color: $border-color_3;
	}
}
.p-colorpicker-preview {
	width: 2rem;
	height: 2rem;
}
.p-colorpicker-panel {
	background: #323232;
	border: 1px solid #191919;
	.p-colorpicker-color-handle {
		border-color: $border-color_6;
	}
	.p-colorpicker-hue-handle {
		border-color: $border-color_6;
	}
}
.p-colorpicker-overlay-panel {
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
}
.p-dropdown {
	background: #ffffff;
	border: 1px solid #605e5c;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: $borderRadius;
	&:not(.p-disabled) {
		&:hover {
			border-color: $border-color_1;
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
		border-color: $border-color_2;
		box-shadow: inset 0 0 0 1px $background-color_primary;
	}
	.p-dropdown-label {
		background: transparent;
		border: 0 none;
		&:enabled {
			&:focus {
				outline: 0 none;
				box-shadow: none;
			}
		}
	}
	.p-dropdown-label.p-placeholder {
		color: $color_2;
	}
	.p-dropdown-trigger {
		background: transparent;
		color: $color_2;
		width: 2.357rem;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
	.p-dropdown-clear-icon {
		color: $color_2;
		right: 2.357rem;
	}
}
.p-dropdown.p-dropdown-clearable {
	.p-dropdown-label {
		padding-right: 1.5rem;
	}
}
.p-dropdown.p-invalid.p-component {
	border-color: $border-color_3;
}
.p-dropdown-panel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	.p-dropdown-header {
		padding: 0.75rem 0.5rem;
		border-bottom: 1px solid $border-color_22;
		color: $color_3;
		background: #ffffff;
		margin: 0;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-dropdown-filter {
			padding-right: 1.5rem;
			margin-right: -1.5rem;
		}
		.p-dropdown-filter-icon {
			right: 0.5rem;
			color: $color_2;
		}
		.p-dropdown-clearable-filter {
			.p-dropdown-filter {
				padding-right: 3rem;
				margin-right: -3rem;
			}
			.p-dropdown-filter-clear-icon {
				right: 2rem;
			}
		}
	}
	.p-dropdown-items {
		padding: 0;
		.p-dropdown-item {
			margin: 0;
			padding: 0.75rem 0.5rem;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: $color_3;
						background: #f3f2f1;
					}
				}
			}
		}
		.p-dropdown-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
		.p-dropdown-item-group {
			margin: 0;
			padding: 0.75rem 0.5rem;
			color: $color_primary;
			background: #ffffff;
			font-weight: 600;
		}
		.p-dropdown-empty-message {
			padding: 0.75rem 0.5rem;
			color: $color_3;
			background: transparent;
		}
	}
}
.p-editor-container {
	.p-editor-toolbar {
		background: #faf9f8;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}
	.p-editor-toolbar.ql-snow {
		border: 1px solid #a19f9d;
		.ql-stroke {
			stroke: #605e5c;
		}
		.ql-fill {
			fill: #605e5c;
		}
		.ql-picker {
			.ql-picker-label {
				border: 0 none;
				color: $color_2;
				&:hover {
					color: $color_3;
					.ql-stroke {
						stroke: #323130;
					}
					.ql-fill {
						fill: #323130;
					}
				}
			}
		}
		.ql-picker.ql-expanded {
			.ql-picker-label {
				color: $color_3;
				.ql-stroke {
					stroke: #323130;
				}
				.ql-fill {
					fill: #323130;
				}
			}
			.ql-picker-options {
				background: #ffffff;
				border: 0 none;
				box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
				border-radius: $borderRadius;
				padding: 0;
				.ql-picker-item {
					color: $color_3;
					&:hover {
						color: $color_3;
						background: #f3f2f1;
					}
				}
			}
			&:not(.ql-icon-picker) {
				.ql-picker-item {
					padding: 0.75rem 0.5rem;
				}
			}
		}
	}
	.p-editor-content {
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
		.ql-editor {
			background: #ffffff;
			color: $color_3;
			border-bottom-right-radius: $borderRadius;
			border-bottom-left-radius: $borderRadius;
		}
	}
	.p-editor-content.ql-snow {
		border: 1px solid #a19f9d;
	}
	.ql-snow.ql-toolbar {
		button {
			&:hover {
				color: $color_3;
				.ql-stroke {
					stroke: #323130;
				}
				.ql-fill {
					fill: #323130;
				}
			}
			&:focus {
				color: $color_3;
				.ql-stroke {
					stroke: #323130;
				}
				.ql-fill {
					fill: #323130;
				}
			}
		}
		button.ql-active {
			color: $color_primary;
			.ql-stroke {
				stroke: $background-color_primary;
			}
			.ql-fill {
				fill: $background-color_primary;
			}
			.ql-picker-label {
				color: $color_primary;
			}
		}
		.ql-picker-label.ql-active {
			color: $color_primary;
			.ql-stroke {
				stroke: $background-color_primary;
			}
			.ql-fill {
				fill: $background-color_primary;
			}
			.ql-picker-label {
				color: $color_primary;
			}
		}
		.ql-picker-item.ql-selected {
			color: $color_primary;
			.ql-stroke {
				stroke: $background-color_primary;
			}
			.ql-fill {
				fill: $background-color_primary;
			}
			.ql-picker-label {
				color: $color_primary;
			}
		}
	}
}
.p-inputgroup-addon {
	background: #f3f2f1;
	color: $color_2;
	border-top: 1px solid #605e5c;
	border-left: 1px solid #605e5c;
	border-bottom: 1px solid #605e5c;
	padding: 0.5rem 0.5rem;
	min-width: 2.357rem;
	&:last-child {
		border-right: 1px solid #605e5c;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
	&:first-child {
		border-top-left-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}
}
.p-inputgroup {
	>.p-component {
		border-radius: $borderRadius;
		margin: 0;
		+ {
			.p-inputgroup-addon {
				border-left: 0 none;
			}
		}
		&:focus {
			z-index: 1;
			~ {
				label {
					z-index: 1;
				}
			}
		}
	}
	>.p-inputwrapper {
		>.p-inputtext {
			border-radius: $borderRadius;
			margin: 0;
			+ {
				.p-inputgroup-addon {
					border-left: 0 none;
				}
			}
			&:focus {
				z-index: 1;
				~ {
					label {
						z-index: 1;
					}
				}
			}
		}
		&:first-child {
			border-top-left-radius: $borderRadius;
			border-bottom-left-radius: $borderRadius;
			>.p-inputtext {
				border-top-left-radius: $borderRadius;
				border-bottom-left-radius: $borderRadius;
			}
		}
		&:last-child {
			border-top-right-radius: $borderRadius;
			border-bottom-right-radius: $borderRadius;
			>.p-inputtext {
				border-top-right-radius: $borderRadius;
				border-bottom-right-radius: $borderRadius;
			}
		}
	}
	>.p-float-label {
		>.p-component {
			border-radius: $borderRadius;
			margin: 0;
			+ {
				.p-inputgroup-addon {
					border-left: 0 none;
				}
			}
			&:focus {
				z-index: 1;
				~ {
					label {
						z-index: 1;
					}
				}
			}
		}
	}
	button {
		&:first-child {
			border-top-left-radius: $borderRadius;
			border-bottom-left-radius: $borderRadius;
		}
		&:last-child {
			border-top-right-radius: $borderRadius;
			border-bottom-right-radius: $borderRadius;
		}
	}
	input {
		&:first-child {
			border-top-left-radius: $borderRadius;
			border-bottom-left-radius: $borderRadius;
		}
		&:last-child {
			border-top-right-radius: $borderRadius;
			border-bottom-right-radius: $borderRadius;
		}
	}
	.p-float-label {
		&:first-child {
			input {
				border-top-left-radius: $borderRadius;
				border-bottom-left-radius: $borderRadius;
			}
		}
		&:last-child {
			input {
				border-top-right-radius: $borderRadius;
				border-bottom-right-radius: $borderRadius;
			}
		}
	}
}
.p-fluid {
	.p-inputgroup {
		.p-button {
			width: auto;
		}
		.p-button.p-button-icon-only {
			width: 2.357rem;
		}
	}
	.p-button {
		width: 100%;
	}
	.p-button-icon-only {
		width: 2.357rem;
	}
	.p-buttonset {
		display: flex;
		.p-button {
			flex: 1;
		}
	}
}
.p-inputnumber.p-invalid.p-component {
	>.p-inputtext {
		border-color: $border-color_3;
	}
}
.p-inputswitch {
	width: 3rem;
	height: 1.75rem;
	width: 40px;
	height: 20px;
	.p-inputswitch-slider {
		background: #ffffff;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		border-radius: 30px;
		border: 1px solid #605e5c;
		&:before {
			background: #605e5c;
			width: 1.25rem;
			height: 1.25rem;
			left: 0.25rem;
			margin-top: -0.625rem;
			border-radius: 50%;
			transition-duration: 0.2s;
			width: 12px;
			height: 12px;
			margin-top: -6px;
		}
	}
	&:not(.p-disabled) {
		&:hover {
			.p-inputswitch-slider {
				background: #ffffff;
				border-color: $border-color_1;
				&:before {
					background-color: $background-color_17;
				}
			}
		}
	}
}
.p-inputswitch.p-inputswitch-checked {
	.p-inputswitch-slider {
		&:before {
			transform: translateX(1.25rem);
			background: #ffffff;
		}
		background: $background-color_primary;
		border-color: $border-color_2;
	}
	&:not(.p-disabled) {
		&:hover {
			.p-inputswitch-slider {
				background: #005a9e;
				border-color: $border-color_2;
				&:before {
					background-color: $background-color_5;
				}
			}
		}
	}
}
.p-inputswitch.p-focus {
	.p-inputswitch-slider {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
		box-shadow: none;
		outline: 1px solid #605e5c;
		outline-offset: 2px;
	}
}
.p-inputswitch.p-invalid {
	.p-inputswitch-slider {
		border-color: $border-color_3;
	}
}
.p-inputtext {
	font-family: $font-family_1;
	font-size: 1rem;
	color: $color_3;
	background: #ffffff;
	padding: 0.5rem 0.5rem;
	border: 1px solid #605e5c;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	appearance: none;
	border-radius: $borderRadius;
	&:enabled {
		&:hover {
			border-color: $border-color_1;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
			border-color: $border-color_2;
			box-shadow: inset 0 0 0 1px $background-color_primary;
		}
	}
	&:disabled {
		background-color: $background-color_18;
		border-color: $border-color_26;
		color: $color_20;
		opacity: 1;
		user-select: none;
	}
}
.p-inputtext.p-invalid.p-component {
	border-color: $border-color_3;
}
.p-inputtext.p-inputtext-sm {
	font-size: 0.875rem;
	padding: 0.4375rem 0.4375rem;
}
.p-inputtext.p-inputtext-lg {
	font-size: 1.25rem;
	padding: 0.625rem 0.625rem;
}
.p-float-label {
	>label {
		left: 0.5rem;
		color: $color_2;
		transition-duration: 0.2s;
	}
	>label.p-error {
		color: $color_1;
	}
}
.p-input-icon-left {
	>i {
		&:first-of-type {
			left: 0.5rem;
			color: $color_2;
		}
	}
	>svg {
		&:first-of-type {
			left: 0.5rem;
			color: $color_2;
		}
	}
	>.p-input-prefix {
		left: 0.5rem;
		color: $color_2;
	}
	>.p-inputtext {
		padding-left: 2rem;
	}
}
.p-input-icon-left.p-float-label {
	>label {
		left: 2rem;
	}
}
.p-input-icon-right {
	>i {
		&:last-of-type {
			right: 0.5rem;
			color: $color_2;
		}
	}
	>svg {
		&:last-of-type {
			right: 0.5rem;
			color: $color_2;
		}
	}
	>.p-input-suffix {
		right: 0.5rem;
		color: $color_2;
	}
	>.p-inputtext {
		padding-right: 2rem;
	}
}
::-webkit-input-placeholder {
	color: $color_2;
}
:-moz-placeholder {
	color: $color_2;
}
::-moz-placeholder {
	color: $color_2;
}
:-ms-input-placeholder {
	color: $color_2;
}
.p-inputtext-sm {
	.p-inputtext {
		font-size: 0.875rem;
		padding: 0.4375rem 0.4375rem;
	}
}
.p-inputtext-lg {
	.p-inputtext {
		font-size: 1.25rem;
		padding: 0.625rem 0.625rem;
	}
}
.p-listbox {
	background: #ffffff;
	color: $color_3;
	border: 1px solid #605e5c;
	border-radius: $borderRadius;
	.p-listbox-header {
		padding: 0.75rem 0.5rem;
		border-bottom: 1px solid $border-color_22;
		color: $color_3;
		background: #ffffff;
		margin: 0;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-listbox-filter {
			padding-right: 1.5rem;
		}
		.p-listbox-filter-icon {
			right: 0.5rem;
			color: $color_2;
		}
	}
	.p-listbox-list {
		padding: 0;
		.p-listbox-item {
			margin: 0;
			padding: 0.75rem 0.5rem;
			border: 1px solid transparent;
			color: $color_3;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-listbox-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
		.p-listbox-item-group {
			margin: 0;
			padding: 0.75rem 0.5rem;
			color: $color_primary;
			background: #ffffff;
			font-weight: 600;
		}
		.p-listbox-empty-message {
			padding: 0.75rem 0.5rem;
			color: $color_3;
			background: transparent;
		}
	}
	&:not(.p-disabled) {
		.p-listbox-item {
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: $color_3;
						background: #f3f2f1;
					}
				}
			}
		}
	}
}
.p-listbox.p-invalid {
	border-color: $border-color_3;
}
.p-mention-panel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	.p-mention-items {
		padding: 0;
		.p-mention-item {
			margin: 0;
			padding: 0.75rem 0.5rem;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:hover {
				color: $color_3;
				background: #f3f2f1;
			}
		}
		.p-mention-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
	}
}
.p-multiselect {
	background: #ffffff;
	border: 1px solid #605e5c;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: $borderRadius;
	&:not(.p-disabled) {
		&:hover {
			border-color: $border-color_1;
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
		border-color: $border-color_2;
		box-shadow: inset 0 0 0 1px $background-color_primary;
	}
	.p-multiselect-label {
		padding: 0.5rem 0.5rem;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}
	.p-multiselect-label.p-placeholder {
		color: $color_2;
	}
	.p-multiselect-trigger {
		background: transparent;
		color: $color_2;
		width: 2.357rem;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
	.p-multiselect-clear-icon {
		color: $color_2;
		right: 2.357rem;
	}
}
.p-multiselect.p-multiselect-clearable {
	.p-multiselect-label {
		padding-right: 1.5rem;
	}
}
.p-multiselect.p-multiselect-chip {
	.p-multiselect-token {
		padding: 0.25rem 0.5rem;
		margin-right: 0.5rem;
		background: $background-color_10;
		color: $color_3;
		border-radius: $borderRadius;
		.p-multiselect-token-icon {
			margin-left: 0.5rem;
		}
	}
}
.p-multiselect.p-invalid.p-component {
	border-color: $border-color_3;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip {
	.p-multiselect-label {
		padding: 0.25rem 0.5rem;
	}
	.p-multiselect-label.p-multiselect-items-label {
		padding: 0.5rem 0.5rem;
	}
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable {
	.p-multiselect-label {
		padding-right: 1.5rem;
	}
}
.p-multiselect-panel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	.p-multiselect-header {
		padding: 0.75rem 0.5rem;
		border-bottom: 1px solid $border-color_22;
		color: $color_3;
		background: #ffffff;
		margin: 0;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-multiselect-filter-container {
			margin: 0 0.5rem;
			.p-inputtext {
				padding-right: 1.5rem;
			}
			.p-multiselect-filter-icon {
				right: 0.5rem;
				color: $color_2;
			}
		}
		.p-multiselect-close {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
	.p-multiselect-items {
		padding: 0;
		.p-multiselect-item {
			margin: 0;
			padding: 0.75rem 0.5rem;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: $color_3;
						background: #f3f2f1;
					}
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
			.p-checkbox {
				margin-right: 0.5rem;
			}
		}
		.p-multiselect-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
		.p-multiselect-item-group {
			margin: 0;
			padding: 0.75rem 0.5rem;
			color: $color_primary;
			background: #ffffff;
			font-weight: 600;
		}
		.p-multiselect-empty-message {
			padding: 0.75rem 0.5rem;
			color: $color_3;
			background: transparent;
		}
	}
}
.p-password.p-invalid.p-component {
	>.p-inputtext {
		border-color: $border-color_3;
	}
}
.p-password-panel {
	padding: 1rem;
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	border-radius: $borderRadius;
	.p-password-meter {
		margin-bottom: 0.5rem;
		background: $background-color_10;
		.p-password-strength.weak {
			background: #d13438;
		}
		.p-password-strength.medium {
			background: #ffaa44;
		}
		.p-password-strength.strong {
			background: #498205;
		}
	}
}
.p-radiobutton {
	width: 20px;
	height: 20px;
	.p-radiobutton-box {
		border: 1px solid #605e5c;
		background: #ffffff;
		width: 20px;
		height: 20px;
		color: $color_3;
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					border-color: $border-color_1;
				}
			}
		}
		&:not(.p-disabled).p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
			border-color: $border-color_2;
			box-shadow: inset 0 0 0 1px $background-color_primary;
		}
		.p-radiobutton-icon {
			width: 12px;
			height: 12px;
			transition-duration: 0.2s;
			background-color: $background-color_primary;
		}
	}
	.p-radiobutton-box.p-highlight {
		border-color: $border-color_2;
		background: #ffffff;
		&:not(.p-disabled) {
			&:hover {
				border-color: $border-color_5;
				background: #ffffff;
				color: $color_6;
			}
		}
	}
	&:focus {
		outline: 0 none;
	}
	.p-radiobutton-box.p-disabled {
		background-color: $background-color_18;
		border-color: $border-color_26;
		color: $color_20;
		opacity: 1;
		user-select: none;
	}
}
.p-radiobutton.p-invalid {
	>.p-radiobutton-box {
		border-color: $border-color_3;
	}
}
.p-rating {
	.p-rating-icon {
		color: $color_3;
		margin-left: 0.5rem;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		font-size: 1.143rem;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
		&:first-child {
			margin-left: 0;
		}
	}
	.p-rating-icon.p-rating-cancel {
		color: $color_1;
	}
	.p-rating-icon.pi-star-fill {
		color: $color_3;
	}
	&:not(.p-disabled) {
		&:not(.p-readonly) {
			.p-rating-icon {
				&:hover {
					color: $color_primary;
				}
			}
			.p-rating-icon.p-rating-cancel {
				&:hover {
					color: $color_1;
				}
			}
		}
	}
}
.p-selectbutton {
	.p-button {
		background: #ffffff;
		border: 1px solid #605e5c;
		color: $color_3;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-button-icon-left {
			color: $color_2;
		}
		.p-button-icon-right {
			color: $color_2;
		}
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					border-color: $border-color_7;
					color: $color_3;
					.p-button-icon-left {
						color: $color_2;
					}
					.p-button-icon-right {
						color: $color_2;
					}
				}
			}
		}
	}
	.p-button.p-highlight {
		background: $background-color_10;
		border-color: $border-color_7;
		color: $color_3;
		.p-button-icon-left {
			color: $color_3;
		}
		.p-button-icon-right {
			color: $color_3;
		}
		&:hover {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			.p-button-icon-left {
				color: $color_3;
			}
			.p-button-icon-right {
				color: $color_3;
			}
		}
	}
}
.p-selectbutton.p-invalid {
	>.p-button {
		border-color: $border-color_3;
	}
}
.p-slider {
	background: #c8c6c4;
	border: 0 none;
	border-radius: $borderRadius;
	.p-slider-handle {
		height: 16px;
		width: 16px;
		background: #ffffff;
		border: 2px solid #605e5c;
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-slider-range {
		background: #605e5c;
	}
	&:not(.p-disabled) {
		.p-slider-handle {
			&:hover {
				background: #ffffff;
				border-color: $border-color_5;
			}
		}
		&:hover {
			background-color: $background-color_16;
			.p-slider-range {
				background-color: $background-color_primary;
			}
			.p-slider-handle {
				border-color: $border-color_5;
			}
		}
	}
}
.p-slider.p-slider-horizontal {
	height: 4px;
	.p-slider-handle {
		margin-top: -8px;
		margin-left: -8px;
	}
}
.p-slider.p-slider-vertical {
	width: 4px;
	.p-slider-handle {
		margin-left: -8px;
		margin-bottom: -8px;
	}
}
.p-treeselect {
	background: #ffffff;
	border: 1px solid #605e5c;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: $borderRadius;
	&:not(.p-disabled) {
		&:hover {
			border-color: $border-color_1;
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
		border-color: $border-color_2;
	}
	.p-treeselect-label {
		padding: 0.5rem 0.5rem;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}
	.p-treeselect-label.p-placeholder {
		color: $color_2;
	}
	.p-treeselect-trigger {
		background: transparent;
		color: $color_2;
		width: 2.357rem;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
}
.p-treeselect.p-treeselect-chip {
	.p-treeselect-token {
		padding: 0.25rem 0.5rem;
		margin-right: 0.5rem;
		background: $background-color_10;
		color: $color_3;
		border-radius: $borderRadius;
	}
}
.p-treeselect.p-invalid.p-component {
	border-color: $border-color_3;
}
.p-inputwrapper-filled.p-treeselect.p-treeselect-chip {
	.p-treeselect-label {
		padding: 0.25rem 0.5rem;
	}
}
.p-treeselect-panel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	.p-treeselect-header {
		padding: 0.75rem 0.5rem;
		border-bottom: 1px solid $border-color_22;
		color: $color_3;
		background: #ffffff;
		margin: 0;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-treeselect-filter-container {
			margin-right: 0.5rem;
			.p-treeselect-filter {
				padding-right: 1.5rem;
			}
			.p-treeselect-filter-icon {
				right: 0.5rem;
				color: $color_2;
			}
		}
		.p-treeselect-filter-container.p-treeselect-clearable-filter {
			.p-treeselect-filter {
				padding-right: 3rem;
			}
			.p-treeselect-filter-clear-icon {
				right: 2rem;
			}
		}
		.p-treeselect-close {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
	.p-treeselect-items-wrapper {
		.p-tree {
			border: 0 none;
		}
		.p-treeselect-empty-message {
			padding: 0.75rem 0.5rem;
			color: $color_3;
			background: transparent;
		}
	}
}
.p-togglebutton.p-button {
	background: #ffffff;
	border: 1px solid #605e5c;
	color: $color_3;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	.p-button-icon-left {
		color: $color_2;
	}
	.p-button-icon-right {
		color: $color_2;
	}
	&:not(.p-disabled) {
		&:not(.p-highlight) {
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
				.p-button-icon-left {
					color: $color_2;
				}
				.p-button-icon-right {
					color: $color_2;
				}
			}
		}
	}
}
.p-togglebutton.p-button.p-highlight {
	background: $background-color_10;
	border-color: $border-color_7;
	color: $color_3;
	.p-button-icon-left {
		color: $color_3;
	}
	.p-button-icon-right {
		color: $color_3;
	}
	&:hover {
		background: $background-color_10;
		border-color: $border-color_7;
		color: $color_3;
		.p-button-icon-left {
			color: $color_3;
		}
		.p-button-icon-right {
			color: $color_3;
		}
	}
}
.p-togglebutton.p-button.p-invalid {
	>.p-button {
		border-color: $border-color_3;
	}
}
.p-button {
	color: $color_primary_text;
	background: $background-color_primary;
	border: 1px solid $background-color_primary;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: $borderRadius;
	&:enabled {
		&:hover {
			background: $background-color_10;
			color: $color_3;
			border-color: $border-color_10;
		}
		&:active {
			background: $background-color_10;
			color: $color_3;
			border-color: $border-color_10;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #106ebe;
					color: $color_primary_text;
					border-color: $border-color_8;
				}
				&:active {
					background: #005a9e;
					color: $color_primary_text;
					border-color: $border-color_5;
				}
			}
		}
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
	}
	.p-button-icon-left {
		margin-right: 0.5rem;
	}
	.p-button-icon-right {
		margin-left: 0.5rem;
	}
	.p-button-icon-bottom {
		margin-top: 0.5rem;
	}
	.p-button-icon-top {
		margin-bottom: 0.5rem;
	}
	.p-badge {
		margin-left: 0.5rem;
		min-width: 1rem;
		height: 1rem;
		line-height: 1rem;
		color: $color_primary;
		background-color: $background-color_5;
	}
}
.p-button.p-button-outlined {
	background-color: $background-color_3;
	color: $color_primary;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(0, 120, 212, 0.04);
			color: $color_primary;
			border: 1px solid;
		}
		&:active {
			background: rgba(0, 120, 212, 0.16);
			color: $color_primary;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(0, 120, 212, 0.04);
					color: $color_primary;
					border: 1px solid;
				}
				&:active {
					background: rgba(0, 120, 212, 0.16);
					color: $color_primary;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-outlined.p-button-plain {
	color: $color_2;
	border-color: $border-color_7;
	&:enabled {
		&:hover {
			background: #f3f2f1;
			color: $color_2;
		}
		&:active {
			background: $background-color_10;
			color: $color_2;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #f3f2f1;
					color: $color_2;
				}
				&:active {
					background: $background-color_10;
					color: $color_2;
				}
			}
		}
	}
}
.p-button.p-button-text {
	background-color: $background-color_3;
	color: $color_primary;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(0, 120, 212, 0.04);
			color: $color_primary;
			border-color: $border-color_4;
		}
		&:active {
			background: rgba(0, 120, 212, 0.16);
			color: $color_primary;
			border-color: $border-color_4;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(0, 120, 212, 0.04);
					color: $color_primary;
					border-color: $border-color_4;
				}
				&:active {
					background: rgba(0, 120, 212, 0.16);
					color: $color_primary;
					border-color: $border-color_4;
				}
			}
		}
	}
}
.p-button.p-button-text.p-button-plain {
	color: $color_2;
	&:enabled {
		&:hover {
			background: #f3f2f1;
			color: $color_2;
		}
		&:active {
			background: $background-color_10;
			color: $color_2;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #f3f2f1;
					color: $color_2;
				}
				&:active {
					background: $background-color_10;
					color: $color_2;
				}
			}
		}
	}
}
.p-button.p-button-raised {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
	border-radius: 2rem;
}
.p-button.p-button-icon-only {
	width: 2.357rem;
	padding: 0.5rem 0;
	.p-button-icon-left {
		margin: 0;
	}
	.p-button-icon-right {
		margin: 0;
	}
}
.p-button.p-button-icon-only.p-button-rounded {
	border-radius: 50%;
	height: 2.357rem;
}
.p-button.p-button-sm {
	font-size: 0.875rem;
	padding: 0.4375rem 0.875rem;
	.p-button-icon {
		font-size: 0.875rem;
	}
}
.p-button.p-button-lg {
	font-size: 1.25rem;
	padding: 0.625rem 1.25rem;
	.p-button-icon {
		font-size: 1.25rem;
	}
}
.p-button.p-button-loading-label-only.p-button-loading-left {
	.p-button-label {
		margin-left: 0.5rem;
	}
}
.p-button.p-button-loading-label-only.p-button-loading-right {
	.p-button-label {
		margin-right: 0.5rem;
	}
}
.p-button.p-button-loading-label-only.p-button-loading-top {
	.p-button-label {
		margin-top: 0.5rem;
	}
}
.p-button.p-button-loading-label-only.p-button-loading-bottom {
	.p-button-label {
		margin-bottom: 0.5rem;
	}
}
.p-button.p-button-loading-label-only {
	.p-button-loading-icon {
		margin: 0;
	}
}
.p-button.p-button-secondary {
	color: $color_primary_text;
	background: #d45c00;
	border: 1px solid #d45c00;
	&:enabled {
		&:hover {
			background: #bf5300;
			color: $color_primary_text;
			border-color: $border-color_9;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #ffbc88;
		}
		&:active {
			background: #aa4a00;
			color: $color_primary_text;
			border-color: $border-color_10;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #bf5300;
					color: $color_primary_text;
					border-color: $border-color_9;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #ffbc88;
				}
				&:active {
					background: #aa4a00;
					color: $color_primary_text;
					border-color: $border-color_10;
				}
			}
		}
	}
}
.p-buttonset.p-button-secondary {
	>.p-button {
		color: $color_primary_text;
		background: #d45c00;
		border: 1px solid #d45c00;
		&:enabled {
			&:hover {
				background: #bf5300;
				color: $color_primary_text;
				border-color: $border-color_9;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #ffbc88;
			}
			&:active {
				background: #aa4a00;
				color: $color_primary_text;
				border-color: $border-color_10;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #bf5300;
						color: $color_primary_text;
						border-color: $border-color_9;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #ffbc88;
					}
					&:active {
						background: #aa4a00;
						color: $color_primary_text;
						border-color: $border-color_10;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_7;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(212, 92, 0, 0.04);
				color: $color_7;
				border: 1px solid;
			}
			&:active {
				background: rgba(212, 92, 0, 0.16);
				color: $color_7;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(212, 92, 0, 0.04);
						color: $color_7;
						border: 1px solid;
					}
					&:active {
						background: rgba(212, 92, 0, 0.16);
						color: $color_7;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_7;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(212, 92, 0, 0.04);
				border-color: $border-color_4;
				color: $color_7;
			}
			&:active {
				background: rgba(212, 92, 0, 0.16);
				border-color: $border-color_4;
				color: $color_7;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(212, 92, 0, 0.04);
						border-color: $border-color_4;
						color: $color_7;
					}
					&:active {
						background: rgba(212, 92, 0, 0.16);
						border-color: $border-color_4;
						color: $color_7;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-secondary {
	>.p-button {
		color: $color_primary_text;
		background: #d45c00;
		border: 1px solid #d45c00;
		&:enabled {
			&:hover {
				background: #bf5300;
				color: $color_primary_text;
				border-color: $border-color_9;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #ffbc88;
			}
			&:active {
				background: #aa4a00;
				color: $color_primary_text;
				border-color: $border-color_10;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #bf5300;
						color: $color_primary_text;
						border-color: $border-color_9;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #ffbc88;
					}
					&:active {
						background: #aa4a00;
						color: $color_primary_text;
						border-color: $border-color_10;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_7;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(212, 92, 0, 0.04);
				color: $color_7;
				border: 1px solid;
			}
			&:active {
				background: rgba(212, 92, 0, 0.16);
				color: $color_7;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(212, 92, 0, 0.04);
						color: $color_7;
						border: 1px solid;
					}
					&:active {
						background: rgba(212, 92, 0, 0.16);
						color: $color_7;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_7;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(212, 92, 0, 0.04);
				border-color: $border-color_4;
				color: $color_7;
			}
			&:active {
				background: rgba(212, 92, 0, 0.16);
				border-color: $border-color_4;
				color: $color_7;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(212, 92, 0, 0.04);
						border-color: $border-color_4;
						color: $color_7;
					}
					&:active {
						background: rgba(212, 92, 0, 0.16);
						border-color: $border-color_4;
						color: $color_7;
					}
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-secondary {
	color: $color_primary_text;
	background: #d45c00;
	border: 1px solid #d45c00;
	&:enabled {
		&:hover {
			background: #bf5300;
			color: $color_primary_text;
			border-color: $border-color_9;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #ffbc88;
		}
		&:active {
			background: #aa4a00;
			color: $color_primary_text;
			border-color: $border-color_10;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #bf5300;
					color: $color_primary_text;
					border-color: $border-color_9;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #ffbc88;
				}
				&:active {
					background: #aa4a00;
					color: $color_primary_text;
					border-color: $border-color_10;
				}
			}
		}
	}
}
.p-button.p-button-secondary.p-button-outlined {
	background-color: $background-color_3;
	color: $color_7;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(212, 92, 0, 0.04);
			color: $color_7;
			border: 1px solid;
		}
		&:active {
			background: rgba(212, 92, 0, 0.16);
			color: $color_7;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(212, 92, 0, 0.04);
					color: $color_7;
					border: 1px solid;
				}
				&:active {
					background: rgba(212, 92, 0, 0.16);
					color: $color_7;
					border: 1px solid;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-secondary.p-button-outlined {
	background-color: $background-color_3;
	color: $color_7;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(212, 92, 0, 0.04);
			color: $color_7;
			border: 1px solid;
		}
		&:active {
			background: rgba(212, 92, 0, 0.16);
			color: $color_7;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(212, 92, 0, 0.04);
					color: $color_7;
					border: 1px solid;
				}
				&:active {
					background: rgba(212, 92, 0, 0.16);
					color: $color_7;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-secondary.p-button-text {
	background-color: $background-color_3;
	color: $color_7;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(212, 92, 0, 0.04);
			border-color: $border-color_4;
			color: $color_7;
		}
		&:active {
			background: rgba(212, 92, 0, 0.16);
			border-color: $border-color_4;
			color: $color_7;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(212, 92, 0, 0.04);
					border-color: $border-color_4;
					color: $color_7;
				}
				&:active {
					background: rgba(212, 92, 0, 0.16);
					border-color: $border-color_4;
					color: $color_7;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-secondary.p-button-text {
	background-color: $background-color_3;
	color: $color_7;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(212, 92, 0, 0.04);
			border-color: $border-color_4;
			color: $color_7;
		}
		&:active {
			background: rgba(212, 92, 0, 0.16);
			border-color: $border-color_4;
			color: $color_7;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(212, 92, 0, 0.04);
					border-color: $border-color_4;
					color: $color_7;
				}
				&:active {
					background: rgba(212, 92, 0, 0.16);
					border-color: $border-color_4;
					color: $color_7;
				}
			}
		}
	}
}
.p-button.p-button-info {
	color: $color_primary_text;
	background: #00b7c3;
	border: 1px solid #00b7c3;
	&:enabled {
		&:hover {
			background: #00a5b0;
			color: $color_primary_text;
			border-color: $border-color_11;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #81f7ff;
		}
		&:active {
			background: #00929c;
			color: $color_primary_text;
			border-color: $border-color_12;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #00a5b0;
					color: $color_primary_text;
					border-color: $border-color_11;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #81f7ff;
				}
				&:active {
					background: #00929c;
					color: $color_primary_text;
					border-color: $border-color_12;
				}
			}
		}
	}
}
.p-buttonset.p-button-info {
	>.p-button {
		color: $color_primary_text;
		background: #00b7c3;
		border: 1px solid #00b7c3;
		&:enabled {
			&:hover {
				background: #00a5b0;
				color: $color_primary_text;
				border-color: $border-color_11;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #81f7ff;
			}
			&:active {
				background: #00929c;
				color: $color_primary_text;
				border-color: $border-color_12;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #00a5b0;
						color: $color_primary_text;
						border-color: $border-color_11;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #81f7ff;
					}
					&:active {
						background: #00929c;
						color: $color_primary_text;
						border-color: $border-color_12;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_8;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(0, 183, 195, 0.04);
				color: $color_8;
				border: 1px solid;
			}
			&:active {
				background: rgba(0, 183, 195, 0.16);
				color: $color_8;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 183, 195, 0.04);
						color: $color_8;
						border: 1px solid;
					}
					&:active {
						background: rgba(0, 183, 195, 0.16);
						color: $color_8;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_8;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(0, 183, 195, 0.04);
				border-color: $border-color_4;
				color: $color_8;
			}
			&:active {
				background: rgba(0, 183, 195, 0.16);
				border-color: $border-color_4;
				color: $color_8;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 183, 195, 0.04);
						border-color: $border-color_4;
						color: $color_8;
					}
					&:active {
						background: rgba(0, 183, 195, 0.16);
						border-color: $border-color_4;
						color: $color_8;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-info {
	>.p-button {
		color: $color_primary_text;
		background: #00b7c3;
		border: 1px solid #00b7c3;
		&:enabled {
			&:hover {
				background: #00a5b0;
				color: $color_primary_text;
				border-color: $border-color_11;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #81f7ff;
			}
			&:active {
				background: #00929c;
				color: $color_primary_text;
				border-color: $border-color_12;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #00a5b0;
						color: $color_primary_text;
						border-color: $border-color_11;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #81f7ff;
					}
					&:active {
						background: #00929c;
						color: $color_primary_text;
						border-color: $border-color_12;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_8;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(0, 183, 195, 0.04);
				color: $color_8;
				border: 1px solid;
			}
			&:active {
				background: rgba(0, 183, 195, 0.16);
				color: $color_8;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 183, 195, 0.04);
						color: $color_8;
						border: 1px solid;
					}
					&:active {
						background: rgba(0, 183, 195, 0.16);
						color: $color_8;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_8;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(0, 183, 195, 0.04);
				border-color: $border-color_4;
				color: $color_8;
			}
			&:active {
				background: rgba(0, 183, 195, 0.16);
				border-color: $border-color_4;
				color: $color_8;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 183, 195, 0.04);
						border-color: $border-color_4;
						color: $color_8;
					}
					&:active {
						background: rgba(0, 183, 195, 0.16);
						border-color: $border-color_4;
						color: $color_8;
					}
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-info {
	color: $color_primary_text;
	background: #00b7c3;
	border: 1px solid #00b7c3;
	&:enabled {
		&:hover {
			background: #00a5b0;
			color: $color_primary_text;
			border-color: $border-color_11;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #81f7ff;
		}
		&:active {
			background: #00929c;
			color: $color_primary_text;
			border-color: $border-color_12;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #00a5b0;
					color: $color_primary_text;
					border-color: $border-color_11;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #81f7ff;
				}
				&:active {
					background: #00929c;
					color: $color_primary_text;
					border-color: $border-color_12;
				}
			}
		}
	}
}
.p-button.p-button-info.p-button-outlined {
	background-color: $background-color_3;
	color: $color_8;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(0, 183, 195, 0.04);
			color: $color_8;
			border: 1px solid;
		}
		&:active {
			background: rgba(0, 183, 195, 0.16);
			color: $color_8;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(0, 183, 195, 0.04);
					color: $color_8;
					border: 1px solid;
				}
				&:active {
					background: rgba(0, 183, 195, 0.16);
					color: $color_8;
					border: 1px solid;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-info.p-button-outlined {
	background-color: $background-color_3;
	color: $color_8;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(0, 183, 195, 0.04);
			color: $color_8;
			border: 1px solid;
		}
		&:active {
			background: rgba(0, 183, 195, 0.16);
			color: $color_8;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(0, 183, 195, 0.04);
					color: $color_8;
					border: 1px solid;
				}
				&:active {
					background: rgba(0, 183, 195, 0.16);
					color: $color_8;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-info.p-button-text {
	background-color: $background-color_3;
	color: $color_8;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(0, 183, 195, 0.04);
			border-color: $border-color_4;
			color: $color_8;
		}
		&:active {
			background: rgba(0, 183, 195, 0.16);
			border-color: $border-color_4;
			color: $color_8;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(0, 183, 195, 0.04);
					border-color: $border-color_4;
					color: $color_8;
				}
				&:active {
					background: rgba(0, 183, 195, 0.16);
					border-color: $border-color_4;
					color: $color_8;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-info.p-button-text {
	background-color: $background-color_3;
	color: $color_8;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(0, 183, 195, 0.04);
			border-color: $border-color_4;
			color: $color_8;
		}
		&:active {
			background: rgba(0, 183, 195, 0.16);
			border-color: $border-color_4;
			color: $color_8;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(0, 183, 195, 0.04);
					border-color: $border-color_4;
					color: $color_8;
				}
				&:active {
					background: rgba(0, 183, 195, 0.16);
					border-color: $border-color_4;
					color: $color_8;
				}
			}
		}
	}
}
.p-button.p-button-success {
	color: $color_primary_text;
	background: #498205;
	border: 1px solid #498205;
	&:enabled {
		&:hover {
			background: #427505;
			color: $color_primary_text;
			border-color: $border-color_13;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #baf96f;
		}
		&:active {
			background: #3a6804;
			color: $color_primary_text;
			border-color: $border-color_14;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #427505;
					color: $color_primary_text;
					border-color: $border-color_13;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #baf96f;
				}
				&:active {
					background: #3a6804;
					color: $color_primary_text;
					border-color: $border-color_14;
				}
			}
		}
	}
}
.p-buttonset.p-button-success {
	>.p-button {
		color: $color_primary_text;
		background: #498205;
		border: 1px solid #498205;
		&:enabled {
			&:hover {
				background: #427505;
				color: $color_primary_text;
				border-color: $border-color_13;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #baf96f;
			}
			&:active {
				background: #3a6804;
				color: $color_primary_text;
				border-color: $border-color_14;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #427505;
						color: $color_primary_text;
						border-color: $border-color_13;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #baf96f;
					}
					&:active {
						background: #3a6804;
						color: $color_primary_text;
						border-color: $border-color_14;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_9;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(73, 130, 5, 0.04);
				color: $color_9;
				border: 1px solid;
			}
			&:active {
				background: rgba(73, 130, 5, 0.16);
				color: $color_9;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(73, 130, 5, 0.04);
						color: $color_9;
						border: 1px solid;
					}
					&:active {
						background: rgba(73, 130, 5, 0.16);
						color: $color_9;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_9;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(73, 130, 5, 0.04);
				border-color: $border-color_4;
				color: $color_9;
			}
			&:active {
				background: rgba(73, 130, 5, 0.16);
				border-color: $border-color_4;
				color: $color_9;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(73, 130, 5, 0.04);
						border-color: $border-color_4;
						color: $color_9;
					}
					&:active {
						background: rgba(73, 130, 5, 0.16);
						border-color: $border-color_4;
						color: $color_9;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-success {
	>.p-button {
		color: $color_primary_text;
		background: #498205;
		border: 1px solid #498205;
		&:enabled {
			&:hover {
				background: #427505;
				color: $color_primary_text;
				border-color: $border-color_13;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #baf96f;
			}
			&:active {
				background: #3a6804;
				color: $color_primary_text;
				border-color: $border-color_14;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #427505;
						color: $color_primary_text;
						border-color: $border-color_13;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #baf96f;
					}
					&:active {
						background: #3a6804;
						color: $color_primary_text;
						border-color: $border-color_14;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_9;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(73, 130, 5, 0.04);
				color: $color_9;
				border: 1px solid;
			}
			&:active {
				background: rgba(73, 130, 5, 0.16);
				color: $color_9;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(73, 130, 5, 0.04);
						color: $color_9;
						border: 1px solid;
					}
					&:active {
						background: rgba(73, 130, 5, 0.16);
						color: $color_9;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_9;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(73, 130, 5, 0.04);
				border-color: $border-color_4;
				color: $color_9;
			}
			&:active {
				background: rgba(73, 130, 5, 0.16);
				border-color: $border-color_4;
				color: $color_9;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(73, 130, 5, 0.04);
						border-color: $border-color_4;
						color: $color_9;
					}
					&:active {
						background: rgba(73, 130, 5, 0.16);
						border-color: $border-color_4;
						color: $color_9;
					}
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-success {
	color: $color_primary_text;
	background: #498205;
	border: 1px solid #498205;
	&:enabled {
		&:hover {
			background: #427505;
			color: $color_primary_text;
			border-color: $border-color_13;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #baf96f;
		}
		&:active {
			background: #3a6804;
			color: $color_primary_text;
			border-color: $border-color_14;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #427505;
					color: $color_primary_text;
					border-color: $border-color_13;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #baf96f;
				}
				&:active {
					background: #3a6804;
					color: $color_primary_text;
					border-color: $border-color_14;
				}
			}
		}
	}
}
.p-button.p-button-success.p-button-outlined {
	background-color: $background-color_3;
	color: $color_9;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(73, 130, 5, 0.04);
			color: $color_9;
			border: 1px solid;
		}
		&:active {
			background: rgba(73, 130, 5, 0.16);
			color: $color_9;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(73, 130, 5, 0.04);
					color: $color_9;
					border: 1px solid;
				}
				&:active {
					background: rgba(73, 130, 5, 0.16);
					color: $color_9;
					border: 1px solid;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-success.p-button-outlined {
	background-color: $background-color_3;
	color: $color_9;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(73, 130, 5, 0.04);
			color: $color_9;
			border: 1px solid;
		}
		&:active {
			background: rgba(73, 130, 5, 0.16);
			color: $color_9;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(73, 130, 5, 0.04);
					color: $color_9;
					border: 1px solid;
				}
				&:active {
					background: rgba(73, 130, 5, 0.16);
					color: $color_9;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-success.p-button-text {
	background-color: $background-color_3;
	color: $color_9;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(73, 130, 5, 0.04);
			border-color: $border-color_4;
			color: $color_9;
		}
		&:active {
			background: rgba(73, 130, 5, 0.16);
			border-color: $border-color_4;
			color: $color_9;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(73, 130, 5, 0.04);
					border-color: $border-color_4;
					color: $color_9;
				}
				&:active {
					background: rgba(73, 130, 5, 0.16);
					border-color: $border-color_4;
					color: $color_9;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-success.p-button-text {
	background-color: $background-color_3;
	color: $color_9;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(73, 130, 5, 0.04);
			border-color: $border-color_4;
			color: $color_9;
		}
		&:active {
			background: rgba(73, 130, 5, 0.16);
			border-color: $border-color_4;
			color: $color_9;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(73, 130, 5, 0.04);
					border-color: $border-color_4;
					color: $color_9;
				}
				&:active {
					background: rgba(73, 130, 5, 0.16);
					border-color: $border-color_4;
					color: $color_9;
				}
			}
		}
	}
}
.p-button.p-button-warning {
	color: $color_3;
	background: #ffaa44;
	border: 1px solid #ffaa44;
	&:enabled {
		&:hover {
			background: #ff9b24;
			color: $color_3;
			border-color: $border-color_15;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #ffddb4;
		}
		&:active {
			background: #ff8d03;
			color: $color_3;
			border-color: $border-color_16;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #ff9b24;
					color: $color_3;
					border-color: $border-color_15;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #ffddb4;
				}
				&:active {
					background: #ff8d03;
					color: $color_3;
					border-color: $border-color_16;
				}
			}
		}
	}
}
.p-buttonset.p-button-warning {
	>.p-button {
		color: $color_3;
		background: #ffaa44;
		border: 1px solid #ffaa44;
		&:enabled {
			&:hover {
				background: #ff9b24;
				color: $color_3;
				border-color: $border-color_15;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #ffddb4;
			}
			&:active {
				background: #ff8d03;
				color: $color_3;
				border-color: $border-color_16;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #ff9b24;
						color: $color_3;
						border-color: $border-color_15;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #ffddb4;
					}
					&:active {
						background: #ff8d03;
						color: $color_3;
						border-color: $border-color_16;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_10;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(255, 170, 68, 0.04);
				color: $color_10;
				border: 1px solid;
			}
			&:active {
				background: rgba(255, 170, 68, 0.16);
				color: $color_10;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(255, 170, 68, 0.04);
						color: $color_10;
						border: 1px solid;
					}
					&:active {
						background: rgba(255, 170, 68, 0.16);
						color: $color_10;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_10;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(255, 170, 68, 0.04);
				border-color: $border-color_4;
				color: $color_10;
			}
			&:active {
				background: rgba(255, 170, 68, 0.16);
				border-color: $border-color_4;
				color: $color_10;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(255, 170, 68, 0.04);
						border-color: $border-color_4;
						color: $color_10;
					}
					&:active {
						background: rgba(255, 170, 68, 0.16);
						border-color: $border-color_4;
						color: $color_10;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-warning {
	>.p-button {
		color: $color_3;
		background: #ffaa44;
		border: 1px solid #ffaa44;
		&:enabled {
			&:hover {
				background: #ff9b24;
				color: $color_3;
				border-color: $border-color_15;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #ffddb4;
			}
			&:active {
				background: #ff8d03;
				color: $color_3;
				border-color: $border-color_16;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #ff9b24;
						color: $color_3;
						border-color: $border-color_15;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #ffddb4;
					}
					&:active {
						background: #ff8d03;
						color: $color_3;
						border-color: $border-color_16;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_10;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(255, 170, 68, 0.04);
				color: $color_10;
				border: 1px solid;
			}
			&:active {
				background: rgba(255, 170, 68, 0.16);
				color: $color_10;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(255, 170, 68, 0.04);
						color: $color_10;
						border: 1px solid;
					}
					&:active {
						background: rgba(255, 170, 68, 0.16);
						color: $color_10;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_10;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(255, 170, 68, 0.04);
				border-color: $border-color_4;
				color: $color_10;
			}
			&:active {
				background: rgba(255, 170, 68, 0.16);
				border-color: $border-color_4;
				color: $color_10;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(255, 170, 68, 0.04);
						border-color: $border-color_4;
						color: $color_10;
					}
					&:active {
						background: rgba(255, 170, 68, 0.16);
						border-color: $border-color_4;
						color: $color_10;
					}
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-warning {
	color: $color_3;
	background: #ffaa44;
	border: 1px solid #ffaa44;
	&:enabled {
		&:hover {
			background: #ff9b24;
			color: $color_3;
			border-color: $border-color_15;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #ffddb4;
		}
		&:active {
			background: #ff8d03;
			color: $color_3;
			border-color: $border-color_16;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #ff9b24;
					color: $color_3;
					border-color: $border-color_15;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #ffddb4;
				}
				&:active {
					background: #ff8d03;
					color: $color_3;
					border-color: $border-color_16;
				}
			}
		}
	}
}
.p-button.p-button-warning.p-button-outlined {
	background-color: $background-color_3;
	color: $color_10;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(255, 170, 68, 0.04);
			color: $color_10;
			border: 1px solid;
		}
		&:active {
			background: rgba(255, 170, 68, 0.16);
			color: $color_10;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(255, 170, 68, 0.04);
					color: $color_10;
					border: 1px solid;
				}
				&:active {
					background: rgba(255, 170, 68, 0.16);
					color: $color_10;
					border: 1px solid;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-warning.p-button-outlined {
	background-color: $background-color_3;
	color: $color_10;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(255, 170, 68, 0.04);
			color: $color_10;
			border: 1px solid;
		}
		&:active {
			background: rgba(255, 170, 68, 0.16);
			color: $color_10;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(255, 170, 68, 0.04);
					color: $color_10;
					border: 1px solid;
				}
				&:active {
					background: rgba(255, 170, 68, 0.16);
					color: $color_10;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-warning.p-button-text {
	background-color: $background-color_3;
	color: $color_10;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(255, 170, 68, 0.04);
			border-color: $border-color_4;
			color: $color_10;
		}
		&:active {
			background: rgba(255, 170, 68, 0.16);
			border-color: $border-color_4;
			color: $color_10;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(255, 170, 68, 0.04);
					border-color: $border-color_4;
					color: $color_10;
				}
				&:active {
					background: rgba(255, 170, 68, 0.16);
					border-color: $border-color_4;
					color: $color_10;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-warning.p-button-text {
	background-color: $background-color_3;
	color: $color_10;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(255, 170, 68, 0.04);
			border-color: $border-color_4;
			color: $color_10;
		}
		&:active {
			background: rgba(255, 170, 68, 0.16);
			border-color: $border-color_4;
			color: $color_10;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(255, 170, 68, 0.04);
					border-color: $border-color_4;
					color: $color_10;
				}
				&:active {
					background: rgba(255, 170, 68, 0.16);
					border-color: $border-color_4;
					color: $color_10;
				}
			}
		}
	}
}
.p-button.p-button-help {
	color: $color_primary_text;
	background: #8378de;
	border: 1px solid #8378de;
	&:enabled {
		&:hover {
			background: #6a5dd7;
			color: $color_primary_text;
			border-color: $border-color_17;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #cdc9f2;
		}
		&:active {
			background: #5141d1;
			color: $color_primary_text;
			border-color: $border-color_18;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #6a5dd7;
					color: $color_primary_text;
					border-color: $border-color_17;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #cdc9f2;
				}
				&:active {
					background: #5141d1;
					color: $color_primary_text;
					border-color: $border-color_18;
				}
			}
		}
	}
}
.p-buttonset.p-button-help {
	>.p-button {
		color: $color_primary_text;
		background: #8378de;
		border: 1px solid #8378de;
		&:enabled {
			&:hover {
				background: #6a5dd7;
				color: $color_primary_text;
				border-color: $border-color_17;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #cdc9f2;
			}
			&:active {
				background: #5141d1;
				color: $color_primary_text;
				border-color: $border-color_18;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #6a5dd7;
						color: $color_primary_text;
						border-color: $border-color_17;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #cdc9f2;
					}
					&:active {
						background: #5141d1;
						color: $color_primary_text;
						border-color: $border-color_18;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_11;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(131, 120, 222, 0.04);
				color: $color_11;
				border: 1px solid;
			}
			&:active {
				background: rgba(131, 120, 222, 0.16);
				color: $color_11;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(131, 120, 222, 0.04);
						color: $color_11;
						border: 1px solid;
					}
					&:active {
						background: rgba(131, 120, 222, 0.16);
						color: $color_11;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_11;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(131, 120, 222, 0.04);
				border-color: $border-color_4;
				color: $color_11;
			}
			&:active {
				background: rgba(131, 120, 222, 0.16);
				border-color: $border-color_4;
				color: $color_11;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(131, 120, 222, 0.04);
						border-color: $border-color_4;
						color: $color_11;
					}
					&:active {
						background: rgba(131, 120, 222, 0.16);
						border-color: $border-color_4;
						color: $color_11;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-help {
	>.p-button {
		color: $color_primary_text;
		background: #8378de;
		border: 1px solid #8378de;
		&:enabled {
			&:hover {
				background: #6a5dd7;
				color: $color_primary_text;
				border-color: $border-color_17;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #cdc9f2;
			}
			&:active {
				background: #5141d1;
				color: $color_primary_text;
				border-color: $border-color_18;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #6a5dd7;
						color: $color_primary_text;
						border-color: $border-color_17;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #cdc9f2;
					}
					&:active {
						background: #5141d1;
						color: $color_primary_text;
						border-color: $border-color_18;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_11;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(131, 120, 222, 0.04);
				color: $color_11;
				border: 1px solid;
			}
			&:active {
				background: rgba(131, 120, 222, 0.16);
				color: $color_11;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(131, 120, 222, 0.04);
						color: $color_11;
						border: 1px solid;
					}
					&:active {
						background: rgba(131, 120, 222, 0.16);
						color: $color_11;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_11;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(131, 120, 222, 0.04);
				border-color: $border-color_4;
				color: $color_11;
			}
			&:active {
				background: rgba(131, 120, 222, 0.16);
				border-color: $border-color_4;
				color: $color_11;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(131, 120, 222, 0.04);
						border-color: $border-color_4;
						color: $color_11;
					}
					&:active {
						background: rgba(131, 120, 222, 0.16);
						border-color: $border-color_4;
						color: $color_11;
					}
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-help {
	color: $color_primary_text;
	background: #8378de;
	border: 1px solid #8378de;
	&:enabled {
		&:hover {
			background: #6a5dd7;
			color: $color_primary_text;
			border-color: $border-color_17;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #cdc9f2;
		}
		&:active {
			background: #5141d1;
			color: $color_primary_text;
			border-color: $border-color_18;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #6a5dd7;
					color: $color_primary_text;
					border-color: $border-color_17;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #cdc9f2;
				}
				&:active {
					background: #5141d1;
					color: $color_primary_text;
					border-color: $border-color_18;
				}
			}
		}
	}
}
.p-button.p-button-help.p-button-outlined {
	background-color: $background-color_3;
	color: $color_11;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(131, 120, 222, 0.04);
			color: $color_11;
			border: 1px solid;
		}
		&:active {
			background: rgba(131, 120, 222, 0.16);
			color: $color_11;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(131, 120, 222, 0.04);
					color: $color_11;
					border: 1px solid;
				}
				&:active {
					background: rgba(131, 120, 222, 0.16);
					color: $color_11;
					border: 1px solid;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-help.p-button-outlined {
	background-color: $background-color_3;
	color: $color_11;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(131, 120, 222, 0.04);
			color: $color_11;
			border: 1px solid;
		}
		&:active {
			background: rgba(131, 120, 222, 0.16);
			color: $color_11;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(131, 120, 222, 0.04);
					color: $color_11;
					border: 1px solid;
				}
				&:active {
					background: rgba(131, 120, 222, 0.16);
					color: $color_11;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-help.p-button-text {
	background-color: $background-color_3;
	color: $color_11;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(131, 120, 222, 0.04);
			border-color: $border-color_4;
			color: $color_11;
		}
		&:active {
			background: rgba(131, 120, 222, 0.16);
			border-color: $border-color_4;
			color: $color_11;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(131, 120, 222, 0.04);
					border-color: $border-color_4;
					color: $color_11;
				}
				&:active {
					background: rgba(131, 120, 222, 0.16);
					border-color: $border-color_4;
					color: $color_11;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-help.p-button-text {
	background-color: $background-color_3;
	color: $color_11;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(131, 120, 222, 0.04);
			border-color: $border-color_4;
			color: $color_11;
		}
		&:active {
			background: rgba(131, 120, 222, 0.16);
			border-color: $border-color_4;
			color: $color_11;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(131, 120, 222, 0.04);
					border-color: $border-color_4;
					color: $color_11;
				}
				&:active {
					background: rgba(131, 120, 222, 0.16);
					border-color: $border-color_4;
					color: $color_11;
				}
			}
		}
	}
}
.p-button.p-button-danger {
	color: $color_primary_text;
	background: #d13438;
	border: 1px solid #d13438;
	&:enabled {
		&:hover {
			background: #c02b2f;
			color: $color_primary_text;
			border-color: $border-color_19;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #edaeaf;
		}
		&:active {
			background: #aa272a;
			color: $color_primary_text;
			border-color: $border-color_20;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #c02b2f;
					color: $color_primary_text;
					border-color: $border-color_19;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #edaeaf;
				}
				&:active {
					background: #aa272a;
					color: $color_primary_text;
					border-color: $border-color_20;
				}
			}
		}
	}
}
.p-buttonset.p-button-danger {
	>.p-button {
		color: $color_primary_text;
		background: #d13438;
		border: 1px solid #d13438;
		&:enabled {
			&:hover {
				background: #c02b2f;
				color: $color_primary_text;
				border-color: $border-color_19;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #edaeaf;
			}
			&:active {
				background: #aa272a;
				color: $color_primary_text;
				border-color: $border-color_20;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #c02b2f;
						color: $color_primary_text;
						border-color: $border-color_19;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #edaeaf;
					}
					&:active {
						background: #aa272a;
						color: $color_primary_text;
						border-color: $border-color_20;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_12;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(209, 52, 56, 0.04);
				color: $color_12;
				border: 1px solid;
			}
			&:active {
				background: rgba(209, 52, 56, 0.16);
				color: $color_12;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(209, 52, 56, 0.04);
						color: $color_12;
						border: 1px solid;
					}
					&:active {
						background: rgba(209, 52, 56, 0.16);
						color: $color_12;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_12;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(209, 52, 56, 0.04);
				border-color: $border-color_4;
				color: $color_12;
			}
			&:active {
				background: rgba(209, 52, 56, 0.16);
				border-color: $border-color_4;
				color: $color_12;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(209, 52, 56, 0.04);
						border-color: $border-color_4;
						color: $color_12;
					}
					&:active {
						background: rgba(209, 52, 56, 0.16);
						border-color: $border-color_4;
						color: $color_12;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-danger {
	>.p-button {
		color: $color_primary_text;
		background: #d13438;
		border: 1px solid #d13438;
		&:enabled {
			&:hover {
				background: #c02b2f;
				color: $color_primary_text;
				border-color: $border-color_19;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #edaeaf;
			}
			&:active {
				background: #aa272a;
				color: $color_primary_text;
				border-color: $border-color_20;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #c02b2f;
						color: $color_primary_text;
						border-color: $border-color_19;
					}
					&:focus {
						box-shadow: 0 0 0 0.2rem #edaeaf;
					}
					&:active {
						background: #aa272a;
						color: $color_primary_text;
						border-color: $border-color_20;
					}
				}
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: $background-color_3;
		color: $color_12;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(209, 52, 56, 0.04);
				color: $color_12;
				border: 1px solid;
			}
			&:active {
				background: rgba(209, 52, 56, 0.16);
				color: $color_12;
				border: 1px solid;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(209, 52, 56, 0.04);
						color: $color_12;
						border: 1px solid;
					}
					&:active {
						background: rgba(209, 52, 56, 0.16);
						color: $color_12;
						border: 1px solid;
					}
				}
			}
		}
	}
	>.p-button.p-button-text {
		background-color: $background-color_3;
		color: $color_12;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(209, 52, 56, 0.04);
				border-color: $border-color_4;
				color: $color_12;
			}
			&:active {
				background: rgba(209, 52, 56, 0.16);
				border-color: $border-color_4;
				color: $color_12;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(209, 52, 56, 0.04);
						border-color: $border-color_4;
						color: $color_12;
					}
					&:active {
						background: rgba(209, 52, 56, 0.16);
						border-color: $border-color_4;
						color: $color_12;
					}
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-danger {
	color: $color_primary_text;
	background: #d13438;
	border: 1px solid #d13438;
	&:enabled {
		&:hover {
			background: #c02b2f;
			color: $color_primary_text;
			border-color: $border-color_19;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #edaeaf;
		}
		&:active {
			background: #aa272a;
			color: $color_primary_text;
			border-color: $border-color_20;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: #c02b2f;
					color: $color_primary_text;
					border-color: $border-color_19;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem #edaeaf;
				}
				&:active {
					background: #aa272a;
					color: $color_primary_text;
					border-color: $border-color_20;
				}
			}
		}
	}
}
.p-button.p-button-danger.p-button-outlined {
	background-color: $background-color_3;
	color: $color_12;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(209, 52, 56, 0.04);
			color: $color_12;
			border: 1px solid;
		}
		&:active {
			background: rgba(209, 52, 56, 0.16);
			color: $color_12;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(209, 52, 56, 0.04);
					color: $color_12;
					border: 1px solid;
				}
				&:active {
					background: rgba(209, 52, 56, 0.16);
					color: $color_12;
					border: 1px solid;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-danger.p-button-outlined {
	background-color: $background-color_3;
	color: $color_12;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(209, 52, 56, 0.04);
			color: $color_12;
			border: 1px solid;
		}
		&:active {
			background: rgba(209, 52, 56, 0.16);
			color: $color_12;
			border: 1px solid;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(209, 52, 56, 0.04);
					color: $color_12;
					border: 1px solid;
				}
				&:active {
					background: rgba(209, 52, 56, 0.16);
					color: $color_12;
					border: 1px solid;
				}
			}
		}
	}
}
.p-button.p-button-danger.p-button-text {
	background-color: $background-color_3;
	color: $color_12;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(209, 52, 56, 0.04);
			border-color: $border-color_4;
			color: $color_12;
		}
		&:active {
			background: rgba(209, 52, 56, 0.16);
			border-color: $border-color_4;
			color: $color_12;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(209, 52, 56, 0.04);
					border-color: $border-color_4;
					color: $color_12;
				}
				&:active {
					background: rgba(209, 52, 56, 0.16);
					border-color: $border-color_4;
					color: $color_12;
				}
			}
		}
	}
}
.p-fileupload-choose.p-button-danger.p-button-text {
	background-color: $background-color_3;
	color: $color_12;
	border-color: $border-color_4;
	&:enabled {
		&:hover {
			background: rgba(209, 52, 56, 0.04);
			border-color: $border-color_4;
			color: $color_12;
		}
		&:active {
			background: rgba(209, 52, 56, 0.16);
			border-color: $border-color_4;
			color: $color_12;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: rgba(209, 52, 56, 0.04);
					border-color: $border-color_4;
					color: $color_12;
				}
				&:active {
					background: rgba(209, 52, 56, 0.16);
					border-color: $border-color_4;
					color: $color_12;
				}
			}
		}
	}
}
.p-button.p-button-link {
	color: $color_primary;
	background: transparent;
	border: transparent;
	&:enabled {
		&:hover {
			background: transparent;
			color: $color_6;
			border-color: $border-color_4;
			.p-button-label {
				text-decoration: underline;
			}
		}
		&:focus {
			background: transparent;
			box-shadow: 0 0 0 1px #605e5c;
			border-color: $border-color_4;
		}
		&:active {
			background: transparent;
			color: $color_primary;
			border-color: $border-color_4;
		}
	}
	&:not(button) {
		&:not(a) {
			&:not(.p-disabled) {
				&:hover {
					background: transparent;
					color: $color_6;
					border-color: $border-color_4;
					.p-button-label {
						text-decoration: underline;
					}
				}
				&:focus {
					background: transparent;
					box-shadow: 0 0 0 1px #605e5c;
					border-color: $border-color_4;
				}
				&:active {
					background: transparent;
					color: $color_primary;
					border-color: $border-color_4;
				}
			}
		}
	}
}
.p-splitbutton {
	border-radius: $borderRadius;
}
.p-splitbutton.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_primary;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(0, 120, 212, 0.04);
				color: $color_primary;
			}
			&:active {
				background: rgba(0, 120, 212, 0.16);
				color: $color_primary;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 120, 212, 0.04);
						color: $color_primary;
					}
					&:active {
						background: rgba(0, 120, 212, 0.16);
						color: $color_primary;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-outlined.p-button-plain {
	>.p-button {
		color: $color_2;
		border-color: $border-color_7;
		&:enabled {
			&:hover {
				background: #f3f2f1;
				color: $color_2;
			}
			&:active {
				background: $background-color_10;
				color: $color_2;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #f3f2f1;
						color: $color_2;
					}
					&:active {
						background: $background-color_10;
						color: $color_2;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_primary;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(0, 120, 212, 0.04);
				color: $color_primary;
				border-color: $border-color_4;
			}
			&:active {
				background: rgba(0, 120, 212, 0.16);
				color: $color_primary;
				border-color: $border-color_4;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 120, 212, 0.04);
						color: $color_primary;
						border-color: $border-color_4;
					}
					&:active {
						background: rgba(0, 120, 212, 0.16);
						color: $color_primary;
						border-color: $border-color_4;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-text.p-button-plain {
	>.p-button {
		color: $color_2;
		&:enabled {
			&:hover {
				background: #f3f2f1;
				color: $color_2;
			}
			&:active {
				background: $background-color_10;
				color: $color_2;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: #f3f2f1;
						color: $color_2;
					}
					&:active {
						background: $background-color_10;
						color: $color_2;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-raised {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-splitbutton.p-button-rounded {
	border-radius: 2rem;
	>.p-button {
		border-radius: 2rem;
	}
}
.p-splitbutton.p-button-sm {
	>.p-button {
		font-size: 0.875rem;
		padding: 0.4375rem 0.875rem;
		.p-button-icon {
			font-size: 0.875rem;
		}
	}
}
.p-splitbutton.p-button-lg {
	>.p-button {
		font-size: 1.25rem;
		padding: 0.625rem 1.25rem;
		.p-button-icon {
			font-size: 1.25rem;
		}
	}
}
.p-splitbutton.p-button-secondary.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_7;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(212, 92, 0, 0.04);
				color: $color_7;
			}
			&:active {
				background: rgba(212, 92, 0, 0.16);
				color: $color_7;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(212, 92, 0, 0.04);
						color: $color_7;
					}
					&:active {
						background: rgba(212, 92, 0, 0.16);
						color: $color_7;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-secondary.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_7;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(212, 92, 0, 0.04);
				border-color: $border-color_4;
				color: $color_7;
			}
			&:active {
				background: rgba(212, 92, 0, 0.16);
				border-color: $border-color_4;
				color: $color_7;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(212, 92, 0, 0.04);
						border-color: $border-color_4;
						color: $color_7;
					}
					&:active {
						background: rgba(212, 92, 0, 0.16);
						border-color: $border-color_4;
						color: $color_7;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-info.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_8;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(0, 183, 195, 0.04);
				color: $color_8;
			}
			&:active {
				background: rgba(0, 183, 195, 0.16);
				color: $color_8;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 183, 195, 0.04);
						color: $color_8;
					}
					&:active {
						background: rgba(0, 183, 195, 0.16);
						color: $color_8;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-info.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_8;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(0, 183, 195, 0.04);
				border-color: $border-color_4;
				color: $color_8;
			}
			&:active {
				background: rgba(0, 183, 195, 0.16);
				border-color: $border-color_4;
				color: $color_8;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(0, 183, 195, 0.04);
						border-color: $border-color_4;
						color: $color_8;
					}
					&:active {
						background: rgba(0, 183, 195, 0.16);
						border-color: $border-color_4;
						color: $color_8;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-success.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_9;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(73, 130, 5, 0.04);
				color: $color_9;
			}
			&:active {
				background: rgba(73, 130, 5, 0.16);
				color: $color_9;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(73, 130, 5, 0.04);
						color: $color_9;
					}
					&:active {
						background: rgba(73, 130, 5, 0.16);
						color: $color_9;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-success.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_9;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(73, 130, 5, 0.04);
				border-color: $border-color_4;
				color: $color_9;
			}
			&:active {
				background: rgba(73, 130, 5, 0.16);
				border-color: $border-color_4;
				color: $color_9;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(73, 130, 5, 0.04);
						border-color: $border-color_4;
						color: $color_9;
					}
					&:active {
						background: rgba(73, 130, 5, 0.16);
						border-color: $border-color_4;
						color: $color_9;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-warning.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_10;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(255, 170, 68, 0.04);
				color: $color_10;
			}
			&:active {
				background: rgba(255, 170, 68, 0.16);
				color: $color_10;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(255, 170, 68, 0.04);
						color: $color_10;
					}
					&:active {
						background: rgba(255, 170, 68, 0.16);
						color: $color_10;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-warning.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_10;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(255, 170, 68, 0.04);
				border-color: $border-color_4;
				color: $color_10;
			}
			&:active {
				background: rgba(255, 170, 68, 0.16);
				border-color: $border-color_4;
				color: $color_10;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(255, 170, 68, 0.04);
						border-color: $border-color_4;
						color: $color_10;
					}
					&:active {
						background: rgba(255, 170, 68, 0.16);
						border-color: $border-color_4;
						color: $color_10;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-help.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_11;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(131, 120, 222, 0.04);
				color: $color_11;
			}
			&:active {
				background: rgba(131, 120, 222, 0.16);
				color: $color_11;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(131, 120, 222, 0.04);
						color: $color_11;
					}
					&:active {
						background: rgba(131, 120, 222, 0.16);
						color: $color_11;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-help.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_11;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(131, 120, 222, 0.04);
				border-color: $border-color_4;
				color: $color_11;
			}
			&:active {
				background: rgba(131, 120, 222, 0.16);
				border-color: $border-color_4;
				color: $color_11;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(131, 120, 222, 0.04);
						border-color: $border-color_4;
						color: $color_11;
					}
					&:active {
						background: rgba(131, 120, 222, 0.16);
						border-color: $border-color_4;
						color: $color_11;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-danger.p-button-outlined {
	>.p-button {
		background-color: $background-color_3;
		color: $color_12;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(209, 52, 56, 0.04);
				color: $color_12;
			}
			&:active {
				background: rgba(209, 52, 56, 0.16);
				color: $color_12;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(209, 52, 56, 0.04);
						color: $color_12;
					}
					&:active {
						background: rgba(209, 52, 56, 0.16);
						color: $color_12;
					}
				}
			}
		}
	}
}
.p-splitbutton.p-button-danger.p-button-text {
	>.p-button {
		background-color: $background-color_3;
		color: $color_12;
		border-color: $border-color_4;
		&:enabled {
			&:hover {
				background: rgba(209, 52, 56, 0.04);
				border-color: $border-color_4;
				color: $color_12;
			}
			&:active {
				background: rgba(209, 52, 56, 0.16);
				border-color: $border-color_4;
				color: $color_12;
			}
		}
		&:not(button) {
			&:not(a) {
				&:not(.p-disabled) {
					&:hover {
						background: rgba(209, 52, 56, 0.04);
						border-color: $border-color_4;
						color: $color_12;
					}
					&:active {
						background: rgba(209, 52, 56, 0.16);
						border-color: $border-color_4;
						color: $color_12;
					}
				}
			}
		}
	}
}
.p-speeddial-button.p-button.p-button-icon-only {
	width: 4rem;
	height: 4rem;
	.p-button-icon {
		font-size: 1.3rem;
	}
}
.p-speeddial-action {
	width: 3rem;
	height: 3rem;
	background: #323130;
	color: $color_primary_text;
	&:hover {
		background: #605e5c;
		color: $color_primary_text;
	}
}
.p-speeddial-direction-up {
	.p-speeddial-item {
		margin: 0.25rem;
		&:first-child {
			margin-bottom: 0.5rem;
		}
	}
}
.p-speeddial-direction-down {
	.p-speeddial-item {
		margin: 0.25rem;
		&:first-child {
			margin-top: 0.5rem;
		}
	}
}
.p-speeddial-direction-left {
	.p-speeddial-item {
		margin: 0 0.25rem;
		&:first-child {
			margin-right: 0.5rem;
		}
	}
}
.p-speeddial-direction-right {
	.p-speeddial-item {
		margin: 0 0.25rem;
		&:first-child {
			margin-left: 0.5rem;
		}
	}
}
.p-speeddial-circle {
	.p-speeddial-item {
		margin: 0;
		&:first-child {
			margin: 0;
		}
		&:last-child {
			margin: 0;
		}
	}
}
.p-speeddial-semi-circle {
	.p-speeddial-item {
		margin: 0;
		&:first-child {
			margin: 0;
		}
		&:last-child {
			margin: 0;
		}
	}
}
.p-speeddial-quarter-circle {
	.p-speeddial-item {
		margin: 0;
		&:first-child {
			margin: 0;
		}
		&:last-child {
			margin: 0;
		}
	}
}
.p-speeddial-mask {
	background-color: $background-color_1;
}
.p-carousel {
	.p-carousel-content {
		.p-carousel-prev {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: 0.5rem;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-carousel-next {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: 0.5rem;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
	.p-carousel-indicators {
		padding: 1rem;
		.p-carousel-indicator {
			margin-right: 0.5rem;
			margin-bottom: 0.5rem;
			button {
				background-color: $background-color_6;
				width: 2rem;
				height: 0.5rem;
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				border-radius: $borderRadius;
				&:hover {
					background: #e1dfdd;
				}
			}
		}
		.p-carousel-indicator.p-highlight {
			button {
				background: $background-color_10;
				color: $color_3;
				background-color: $background-color_primary;
			}
		}
	}
}
.p-datatable {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-datatable-header {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 1px 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
	}
	.p-datatable-footer {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 0 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
	}
	.p-datatable-thead {
		>tr {
			>th {
				text-align: left;
				padding: 0.75rem 0.75rem;
				border: 1px solid #f3f2f1;
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: $color_3;
				background: #ffffff;
				transition: box-shadow 0.2s;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				text-align: left;
				padding: 0.75rem 0.75rem;
				border: 1px solid #f3f2f1;
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: $color_3;
				background: #ffffff;
			}
		}
	}
	.p-sortable-column {
		.p-sortable-column-icon {
			color: $color_2;
			margin-left: 0.5rem;
		}
		.p-sortable-column-badge {
			border-radius: 50%;
			height: 1.143rem;
			min-width: 1.143rem;
			line-height: 1.143rem;
			color: $color_3;
			background: $background-color_10;
			margin-left: 0.5rem;
		}
		&:not(.p-highlight) {
			&:not(.p-sortable-disabled) {
				&:hover {
					background: #f3f2f1;
					color: $color_3;
					.p-sortable-column-icon {
						color: $color_2;
					}
				}
			}
		}
		&:focus {
			box-shadow: $boxShadow;
			outline: 0 none;
		}
	}
	.p-sortable-column.p-highlight {
		background: $background-color_10;
		color: $color_3;
		.p-sortable-column-icon {
			color: $color_3;
		}
		&:not(.p-sortable-disabled) {
			&:hover {
				background: #f3f2f1;
				color: $color_3;
				.p-sortable-column-icon {
					color: $color_3;
				}
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			background: #ffffff;
			color: $color_3;
			transition: box-shadow 0.2s;
			>td {
				text-align: left;
				border: 1px solid #f3f2f1;
				border-width: 0 0 1px 0;
				padding: 0.75rem 0.75rem;
				.p-row-toggler {
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: $color_2;
							border-color: $border-color_4;
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
				.p-row-editor-init {
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: $color_2;
							border-color: $border-color_4;
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
				.p-row-editor-save {
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					margin-right: 0.5rem;
					&:enabled {
						&:hover {
							color: $color_2;
							border-color: $border-color_4;
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
				.p-row-editor-cancel {
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: $color_2;
							border-color: $border-color_4;
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
				>.p-column-title {
					font-weight: 600;
				}
			}
			>td.p-highlight {
				background: $background-color_10;
				color: $color_3;
			}
		}
		>tr.p-highlight {
			background: $background-color_10;
			color: $color_3;
		}
		>tr.p-datatable-dragpoint-top {
			>td {
				box-shadow: inset 0 2px 0 0 $border-color_22;
				box-shadow: inset 0 2px 0 0 $background-color_primary;
			}
		}
		>tr.p-datatable-dragpoint-bottom {
			>td {
				box-shadow: inset 0 -2px 0 0 $border-color_22;
				box-shadow: inset 0 -2px 0 0 $background-color_primary;
			}
		}
	}
	.p-column-resizer-helper {
		background: $background-color_primary;
	}
	.p-datatable-scrollable-header {
		background: #faf9f8;
	}
	.p-datatable-scrollable-footer {
		background: #faf9f8;
	}
	.p-datatable-loading-icon {
		font-size: 2rem;
	}
	font-size: 90%;
}
.p-datatable.p-datatable-selectable {
	.p-datatable-tbody {
		>tr.p-selectable-row {
			&:not(.p-highlight) {
				&:not(.p-datatable-emptymessage) {
					&:hover {
						background: #f3f2f1;
						color: $color_3;
					}
				}
			}
			&:focus {
				outline: 0.15rem solid #605e5c;
				outline-offset: -0.15rem;
			}
		}
	}
}
.p-datatable.p-datatable-selectable-cell {
	.p-datatable-tbody {
		>tr.p-selectable-row {
			>td.p-selectable-cell {
				&:not(.p-highlight) {
					&:hover {
						background: #f3f2f1;
						color: $color_3;
					}
				}
				&:focus {
					outline: 0.15rem solid #605e5c;
					outline-offset: -0.15rem;
				}
			}
		}
	}
}
.p-datatable.p-datatable-hoverable-rows {
	.p-datatable-tbody {
		>tr {
			&:not(.p-highlight) {
				&:not(.p-datatable-emptymessage) {
					&:hover {
						background: #f3f2f1;
						color: $color_3;
					}
				}
			}
		}
	}
}
.p-datatable.p-datatable-scrollable {
	>.p-datatable-wrapper {
		>.p-datatable-table {
			>.p-datatable-thead {
				background-color: $background-color_5;
			}
			>.p-datatable-tfoot {
				background-color: $background-color_5;
			}
		}
	}
}
.p-datatable.p-datatable-gridlines {
	.p-datatable-header {
		border-width: 1px 1px 0 1px;
	}
	.p-datatable-footer {
		border-width: 0 1px 1px 1px;
	}
	.p-paginator-top {
		border-width: 0 1px 0 1px;
	}
	.p-paginator-bottom {
		border-width: 0 1px 1px 1px;
	}
	.p-datatable-thead {
		>tr {
			>th {
				border-width: 1px 1px 1px 1px;
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
}
.p-datatable.p-datatable-striped {
	.p-datatable-tbody {
		>tr.p-row-odd {
			background: #faf9f8;
			+ {
				.p-row-expanded {
					background: #faf9f8;
				}
			}
		}
		>tr.p-row-odd.p-highlight {
			background: $background-color_10;
			color: $color_3;
			.p-row-toggler {
				color: $color_3;
				&:hover {
					color: $color_3;
				}
			}
		}
	}
}
.p-datatable.p-datatable-sm {
	.p-datatable-header {
		padding: 0.375rem 0.375rem;
	}
	.p-datatable-thead {
		>tr {
			>th {
				padding: 0.375rem 0.375rem;
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			>td {
				padding: 0.375rem 0.375rem;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				padding: 0.375rem 0.375rem;
			}
		}
	}
	.p-datatable-footer {
		padding: 0.375rem 0.375rem;
	}
}
.p-datatable.p-datatable-lg {
	.p-datatable-header {
		padding: 0.9375rem 0.9375rem;
	}
	.p-datatable-thead {
		>tr {
			>th {
				padding: 0.9375rem 0.9375rem;
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			>td {
				padding: 0.9375rem 0.9375rem;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				padding: 0.9375rem 0.9375rem;
			}
		}
	}
	.p-datatable-footer {
		padding: 0.9375rem 0.9375rem;
	}
}
.p-datatable-drag-selection-helper {
	background: rgba(0, 120, 212, 0.16);
}
.p-dataview {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-dataview-header {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 1px 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
	}
	.p-dataview-content {
		background: #ffffff;
		color: $color_3;
		border: 0 none;
		padding: 0;
	}
	.p-dataview-footer {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 0 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
		border-bottom-left-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
	.p-dataview-loading-icon {
		font-size: 2rem;
	}
}
.p-dataview.p-dataview-list {
	.p-dataview-content {
		>.p-grid {
			>div {
				border: solid #f3f2f1;
				border-width: 0 0 1px 0;
			}
		}
	}
}
.p-datascroller {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-datascroller-header {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 1px 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
	}
	.p-datascroller-content {
		background: #ffffff;
		color: $color_3;
		border: 0 none;
		padding: 0;
	}
	.p-datascroller-footer {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 0 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
		border-bottom-left-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
}
.p-datascroller.p-datascroller-inline {
	.p-datascroller-list {
		>li {
			border: solid #f3f2f1;
			border-width: 0 0 1px 0;
		}
	}
}
.p-column-filter-row {
	.p-column-filter-menu-button {
		margin-left: 0.5rem;
	}
	.p-column-filter-clear-button {
		margin-left: 0.5rem;
	}
}
.p-column-filter-menu-button {
	width: 2rem;
	height: 2rem;
	color: $color_2;
	border: 0 none;
	background: transparent;
	border-radius: $borderRadius;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: $color_2;
		border-color: $border-color_4;
		background: #f3f2f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
	}
}
.p-column-filter-menu-button.p-column-filter-menu-button-open {
	background: #f3f2f1;
	color: $color_2;
	&:hover {
		background: #f3f2f1;
		color: $color_2;
	}
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
	background: $background-color_10;
	color: $color_3;
	&:hover {
		background: $background-color_10;
		color: $color_3;
	}
}
.p-column-filter-clear-button {
	width: 2rem;
	height: 2rem;
	color: $color_2;
	border: 0 none;
	background: transparent;
	border-radius: $borderRadius;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: $color_2;
		border-color: $border-color_4;
		background: #f3f2f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: $boxShadow;
	}
}
.p-column-filter-overlay {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	min-width: 12.5rem;
	.p-column-filter-row-items {
		padding: 0;
		.p-column-filter-row-item {
			margin: 0;
			padding: 0.75rem 0.5rem;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: $color_3;
						background: #f3f2f1;
					}
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-column-filter-row-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
		.p-column-filter-separator {
			border-top: 1px solid $border-color_22;
			margin: 0.25rem 0;
		}
	}
}
.p-column-filter-overlay-menu {
	.p-column-filter-operator {
		padding: 0.75rem 0.5rem;
		border-bottom: 1px solid $border-color_22;
		color: $color_3;
		background: #ffffff;
		margin: 0;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}
	.p-column-filter-constraint {
		padding: 1rem;
		border-bottom: 1px solid $border-color_22;
		.p-column-filter-matchmode-dropdown {
			margin-bottom: 0.5rem;
		}
		.p-column-filter-remove-button {
			margin-top: 0.5rem;
		}
		&:last-child {
			border-bottom: 0 none;
		}
	}
	.p-column-filter-add-rule {
		padding: 0.5rem 1rem;
	}
	.p-column-filter-buttonbar {
		padding: 1rem;
	}
}
.fc.fc-unthemed {
	.fc-view-container {
		th {
			background: #faf9f8;
			border: 1px solid #a19f9d;
			color: $color_3;
		}
		td.fc-widget-content {
			border: 1px solid #a19f9d;
			color: $color_3;
		}
		td.fc-head-container {
			border: 1px solid #a19f9d;
		}
		.fc-view {
			background: #ffffff;
		}
		.fc-row {
			border-right: 1px solid #a19f9d;
		}
		.fc-event {
			background: $background-color_primary;
			border: 1px solid $background-color_primary;
			color: $color_primary_text;
		}
		.fc-divider {
			background: #faf9f8;
			border: 1px solid #a19f9d;
		}
	}
	.fc-toolbar {
		.fc-button {
			color: $color_primary_text;
			background: $background-color_primary;
			border: 1px solid $background-color_primary;
			font-size: 1rem;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			border-radius: $borderRadius;
			display: flex;
			align-items: center;
			&:enabled {
				&:hover {
					background: #106ebe;
					color: $color_primary_text;
					border-color: $border-color_8;
				}
				&:active {
					background: #005a9e;
					color: $color_primary_text;
					border-color: $border-color_5;
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
			}
			.fc-icon-chevron-left {
				font-family: $font-family_2 !important;
				text-indent: 0;
				font-size: 1rem;
				&:before {
					content: "\e900";
				}
			}
			.fc-icon-chevron-right {
				font-family: $font-family_2 !important;
				text-indent: 0;
				font-size: 1rem;
				&:before {
					content: "\e901";
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.fc-button.fc-dayGridMonth-button {
			background: #ffffff;
			border: 1px solid #605e5c;
			color: $color_3;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
				z-index: 1;
			}
		}
		.fc-button.fc-timeGridWeek-button {
			background: #ffffff;
			border: 1px solid #605e5c;
			color: $color_3;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
				z-index: 1;
			}
		}
		.fc-button.fc-timeGridDay-button {
			background: #ffffff;
			border: 1px solid #605e5c;
			color: $color_3;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
				z-index: 1;
			}
		}
		.fc-button.fc-dayGridMonth-button.fc-button-active {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			&:hover {
				background: $background-color_10;
				border-color: $border-color_7;
				color: $color_3;
			}
		}
		.fc-button.fc-timeGridWeek-button.fc-button-active {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			&:hover {
				background: $background-color_10;
				border-color: $border-color_7;
				color: $color_3;
			}
		}
		.fc-button.fc-timeGridDay-button.fc-button-active {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			&:hover {
				background: $background-color_10;
				border-color: $border-color_7;
				color: $color_3;
			}
		}
		.fc-button-group {
			.fc-button {
				border-radius: $borderRadius;
				&:first-child {
					border-top-left-radius: $borderRadius;
					border-bottom-left-radius: $borderRadius;
				}
				&:last-child {
					border-top-right-radius: $borderRadius;
					border-bottom-right-radius: $borderRadius;
				}
			}
		}
	}
}
.fc.fc-theme-standard {
	.fc-view-harness {
		.fc-scrollgrid {
			border-color: $border-color_21;
		}
		th {
			background: #faf9f8;
			border-color: $border-color_21;
			color: $color_3;
		}
		td {
			color: $color_3;
			border-color: $border-color_21;
		}
		.fc-view {
			background: #ffffff;
		}
		.fc-popover {
			background: none;
			border: 0 none;
			.fc-popover-header {
				border: 1px solid #a19f9d;
				padding: 1rem;
				background: #faf9f8;
				color: $color_3;
				.fc-popover-close {
					opacity: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					font-family: $font-family_2 !important;
					font-size: 1rem;
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:before {
						content: "\e90b";
					}
					&:hover {
						color: $color_2;
						border-color: $border-color_4;
						background: #f3f2f1;
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
			}
			.fc-popover-body {
				padding: 1rem;
				border: 1px solid #a19f9d;
				background: #ffffff;
				color: $color_3;
				border-top: 0 none;
			}
		}
		.fc-event.fc-daygrid-block-event {
			color: $color_primary_text;
			background: $background-color_primary;
			border-color: $border-color_2;
			.fc-event-main {
				color: $color_primary_text;
			}
		}
		.fc-event.fc-daygrid-dot-event {
			.fc-daygrid-event-dot {
				background: $background-color_primary;
				border-color: $border-color_2;
			}
			&:hover {
				background: #f3f2f1;
				color: $color_3;
			}
		}
		.fc-cell-shaded {
			background: #faf9f8;
		}
	}
	.fc-toolbar {
		.fc-button {
			color: $color_primary_text;
			background: $background-color_primary;
			border: 1px solid $background-color_primary;
			font-size: 1rem;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			border-radius: $borderRadius;
			&:enabled {
				&:hover {
					background: #106ebe;
					color: $color_primary_text;
					border-color: $border-color_8;
				}
				&:active {
					background: #005a9e;
					color: $color_primary_text;
					border-color: $border-color_5;
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
			}
			&:disabled {
				opacity: 0.6;
				color: $color_primary_text;
				background: $background-color_primary;
				border: 1px solid $background-color_primary;
			}
			.fc-icon-chevron-left {
				font-family: $font-family_2 !important;
				text-indent: 0;
				font-size: 1rem;
				&:before {
					content: "\e900";
				}
			}
			.fc-icon-chevron-right {
				font-family: $font-family_2 !important;
				text-indent: 0;
				font-size: 1rem;
				&:before {
					content: "\e901";
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.fc-button.fc-dayGridMonth-button {
			background: #ffffff;
			border: 1px solid #605e5c;
			color: $color_3;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
			}
			&:not(:disabled) {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
					z-index: 1;
				}
			}
		}
		.fc-button.fc-timeGridWeek-button {
			background: #ffffff;
			border: 1px solid #605e5c;
			color: $color_3;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
			}
			&:not(:disabled) {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
					z-index: 1;
				}
			}
		}
		.fc-button.fc-timeGridDay-button {
			background: #ffffff;
			border: 1px solid #605e5c;
			color: $color_3;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: #f3f2f1;
				border-color: $border-color_7;
				color: $color_3;
			}
			&:not(:disabled) {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
					z-index: 1;
				}
			}
		}
		.fc-button.fc-dayGridMonth-button.fc-button-active {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			&:hover {
				background: $background-color_10;
				border-color: $border-color_7;
				color: $color_3;
			}
		}
		.fc-button.fc-timeGridWeek-button.fc-button-active {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			&:hover {
				background: $background-color_10;
				border-color: $border-color_7;
				color: $color_3;
			}
		}
		.fc-button.fc-timeGridDay-button.fc-button-active {
			background: $background-color_10;
			border-color: $border-color_7;
			color: $color_3;
			&:hover {
				background: $background-color_10;
				border-color: $border-color_7;
				color: $color_3;
			}
		}
		.fc-button-group {
			.fc-button {
				border-radius: $borderRadius;
				&:first-child {
					border-top-left-radius: $borderRadius;
					border-bottom-left-radius: $borderRadius;
				}
				&:last-child {
					border-top-right-radius: $borderRadius;
					border-bottom-right-radius: $borderRadius;
				}
			}
		}
	}
	.fc-highlight {
		color: $color_3;
		background: $background-color_10;
	}
}
.p-orderlist {
	.p-orderlist-controls {
		padding: 1rem;
		.p-button {
			margin-bottom: 0.5rem;
		}
	}
	.p-orderlist-header {
		background: #faf9f8;
		color: $color_3;
		border: 1px solid #a19f9d;
		padding: 1rem;
		font-weight: 600;
		border-bottom: 0 none;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}
	.p-orderlist-list {
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		padding: 0;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
		.p-orderlist-item {
			padding: 0.75rem 0.5rem;
			margin: 0;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: transform 0.2s, box-shadow 0.2s;
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					color: $color_3;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-orderlist-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
	}
}
.p-organizationchart {
	.p-organizationchart-node-content.p-organizationchart-selectable-node {
		&:not(.p-highlight) {
			&:hover {
				background: #f3f2f1;
				color: $color_3;
			}
		}
	}
	.p-organizationchart-node-content.p-highlight {
		background: $background-color_10;
		color: $color_3;
		.p-node-toggler {
			i {
				color: $color_13;
			}
		}
	}
	.p-organizationchart-line-down {
		background: #a19f9d;
	}
	.p-organizationchart-line-left {
		border-right: 1px solid #a19f9d;
		border-color: $border-color_21;
	}
	.p-organizationchart-line-top {
		border-top: 1px solid #a19f9d;
		border-color: $border-color_21;
	}
	.p-organizationchart-node-content {
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		padding: 1rem;
		.p-node-toggler {
			background: inherit;
			color: $color_14;
			border-radius: 50%;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
}
.p-paginator {
	background: #ffffff;
	color: $color_2;
	border: solid #f3f2f1;
	border-width: 0;
	padding: 0.5rem 1rem;
	border-radius: $borderRadius;
	.p-paginator-first {
		background-color: $background-color_3;
		border: 0 none;
		color: $color_2;
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					border-color: $border-color_4;
					color: $color_3;
				}
			}
		}
	}
	.p-paginator-prev {
		background-color: $background-color_3;
		border: 0 none;
		color: $color_2;
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: $borderRadius;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					border-color: $border-color_4;
					color: $color_3;
				}
			}
		}
	}
	.p-paginator-next {
		background-color: $background-color_3;
		border: 0 none;
		color: $color_2;
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: $borderRadius;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					border-color: $border-color_4;
					color: $color_3;
				}
			}
		}
	}
	.p-paginator-last {
		background-color: $background-color_3;
		border: 0 none;
		color: $color_2;
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: $borderRadius;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					border-color: $border-color_4;
					color: $color_3;
				}
			}
		}
	}
	.p-dropdown {
		margin-left: 0.5rem;
		height: 2.357rem;
		.p-dropdown-label {
			padding-right: 0;
		}
	}
	.p-paginator-page-input {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		.p-inputtext {
			max-width: 2.357rem;
		}
	}
	.p-paginator-current {
		background-color: $background-color_3;
		border: 0 none;
		color: $color_2;
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		padding: 0 0.5rem;
	}
	.p-paginator-pages {
		.p-paginator-page {
			background-color: $background-color_3;
			border: 0 none;
			color: $color_2;
			min-width: 2.357rem;
			height: 2.357rem;
			margin: 0.143rem;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					border-color: $border-color_4;
					color: $color_3;
				}
			}
		}
		.p-paginator-page.p-highlight {
			background: $background-color_10;
			border-color: $border-color_22;
			color: $color_3;
		}
	}
}
.p-picklist {
	.p-picklist-buttons {
		padding: 1rem;
		.p-button {
			margin-bottom: 0.5rem;
		}
	}
	.p-picklist-header {
		background: #faf9f8;
		color: $color_3;
		border: 1px solid #a19f9d;
		padding: 1rem;
		font-weight: 600;
		border-bottom: 0 none;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}
	.p-picklist-filter-container {
		padding: 1rem;
		background: #ffffff;
		border: 1px solid #a19f9d;
		border-bottom: 0 none;
		.p-picklist-filter-input {
			padding-right: 1.5rem;
		}
		.p-picklist-filter-icon {
			right: 0.5rem;
			color: $color_2;
		}
	}
	.p-picklist-list {
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		padding: 0;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
		.p-picklist-item {
			padding: 0.75rem 0.5rem;
			margin: 0;
			border: 1px solid transparent;
			color: $color_3;
			background: transparent;
			transition: transform 0.2s, box-shadow 0.2s;
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					color: $color_3;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-picklist-item.p-highlight {
			color: $color_3;
			background: $background-color_10;
		}
	}
}
.p-tree {
	border: 1px solid #a19f9d;
	background: #ffffff;
	color: $color_3;
	padding: 1rem;
	border-radius: $borderRadius;
	.p-tree-container {
		.p-treenode {
			padding: 0.143rem;
			.p-treenode-content {
				border-radius: $borderRadius;
				transition: box-shadow 0.2s;
				padding: 0.5rem;
				.p-tree-toggler {
					margin-right: 0.5rem;
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: $color_2;
							border-color: $border-color_4;
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
				}
				.p-treenode-icon {
					margin-right: 0.5rem;
					color: $color_2;
				}
				.p-checkbox {
					margin-right: 0.5rem;
					.p-indeterminate {
						.p-checkbox-icon {
							color: $color_3;
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
			.p-treenode-content.p-highlight {
				background: $background-color_10;
				color: $color_3;
				.p-tree-toggler {
					color: $color_3;
					&:hover {
						color: $color_3;
					}
				}
				.p-treenode-icon {
					color: $color_3;
					&:hover {
						color: $color_3;
					}
				}
			}
			.p-treenode-content.p-treenode-selectable {
				&:not(.p-highlight) {
					&:hover {
						background: #f3f2f1;
						color: $color_3;
					}
				}
			}
			.p-treenode-content.p-treenode-dragover {
				background: #f3f2f1;
				color: $color_3;
			}
		}
	}
	.p-tree-filter-container {
		margin-bottom: 0.5rem;
		.p-tree-filter {
			width: 100%;
			padding-right: 1.5rem;
		}
		.p-tree-filter-icon {
			right: 0.5rem;
			color: $color_2;
		}
	}
	.p-treenode-children {
		padding: 0 0 0 1rem;
	}
	.p-tree-loading-icon {
		font-size: 2rem;
	}
	.p-treenode-droppoint {
		height: 0.5rem;
	}
	.p-treenode-droppoint.p-treenode-droppoint-active {
		background: #c3bcb5;
	}
}
.p-treetable {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: $borderRadius;
	}
	.p-treetable-header {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 1px 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
	}
	.p-treetable-footer {
		background: #ffffff;
		color: $color_3;
		border: 1px solid #f3f2f1;
		border-width: 0 0 1px 0;
		padding: 0.75rem 0.75rem;
		font-weight: 600;
	}
	.p-treetable-thead {
		>tr {
			>th {
				text-align: left;
				padding: 0.75rem 0.75rem;
				border: 1px solid #f3f2f1;
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: $color_3;
				background: #ffffff;
				transition: box-shadow 0.2s;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				text-align: left;
				padding: 0.75rem 0.75rem;
				border: 1px solid #f3f2f1;
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: $color_3;
				background: #ffffff;
			}
		}
	}
	.p-sortable-column {
		outline-color: $outline-color_1;
		.p-sortable-column-icon {
			color: $color_2;
			margin-left: 0.5rem;
		}
		.p-sortable-column-badge {
			border-radius: 50%;
			height: 1.143rem;
			min-width: 1.143rem;
			line-height: 1.143rem;
			color: $color_3;
			background: $background-color_10;
			margin-left: 0.5rem;
		}
		&:not(.p-highlight) {
			&:hover {
				background: #f3f2f1;
				color: $color_3;
				.p-sortable-column-icon {
					color: $color_2;
				}
			}
		}
	}
	.p-sortable-column.p-highlight {
		background: $background-color_10;
		color: $color_3;
		.p-sortable-column-icon {
			color: $color_3;
		}
	}
	.p-treetable-tbody {
		>tr {
			background: #ffffff;
			color: $color_3;
			transition: box-shadow 0.2s;
			>td {
				text-align: left;
				border: 1px solid #f3f2f1;
				border-width: 0 0 1px 0;
				padding: 0.75rem 0.75rem;
				.p-treetable-toggler {
					width: 2rem;
					height: 2rem;
					color: $color_2;
					border: 0 none;
					background: transparent;
					border-radius: $borderRadius;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					margin-right: 0.5rem;
					&:enabled {
						&:hover {
							color: $color_2;
							border-color: $border-color_4;
							background: #f3f2f1;
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
					+ {
						.p-checkbox {
							margin-right: 0.5rem;
							.p-indeterminate {
								.p-checkbox-icon {
									color: $color_3;
								}
							}
						}
					}
				}
			}
			&:focus {
				outline: 0.15rem solid #605e5c;
				outline-offset: -0.15rem;
			}
		}
		>tr.p-highlight {
			background: $background-color_10;
			color: $color_3;
			.p-treetable-toggler {
				color: $color_3;
				&:hover {
					color: $color_3;
				}
			}
		}
	}
	.p-column-resizer-helper {
		background: $background-color_primary;
	}
	.p-treetable-scrollable-header {
		background: #faf9f8;
	}
	.p-treetable-scrollable-footer {
		background: #faf9f8;
	}
	.p-treetable-loading-icon {
		font-size: 2rem;
	}
}
.p-treetable.p-treetable-selectable {
	.p-treetable-tbody {
		>tr {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					color: $color_3;
					.p-treetable-toggler {
						color: $color_3;
					}
				}
			}
		}
	}
}
.p-treetable.p-treetable-hoverable-rows {
	.p-treetable-tbody {
		>tr {
			&:not(.p-highlight) {
				&:hover {
					background: #f3f2f1;
					color: $color_3;
					.p-treetable-toggler {
						color: $color_3;
					}
				}
			}
		}
	}
}
.p-treetable.p-treetable-gridlines {
	.p-datatable-header {
		border-width: 1px 1px 0 1px;
	}
	.p-treetable-footer {
		border-width: 0 1px 1px 1px;
	}
	.p-treetable-top {
		border-width: 0 1px 0 1px;
	}
	.p-treetable-bottom {
		border-width: 0 1px 1px 1px;
	}
	.p-treetable-thead {
		>tr {
			>th {
				border-width: 1px;
			}
		}
	}
	.p-treetable-tbody {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
}
.p-treetable.p-treetable-sm {
	.p-treetable-header {
		padding: 0.65625rem 0.65625rem;
	}
	.p-treetable-thead {
		>tr {
			>th {
				padding: 0.375rem 0.375rem;
			}
		}
	}
	.p-treetable-tbody {
		>tr {
			>td {
				padding: 0.375rem 0.375rem;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				padding: 0.375rem 0.375rem;
			}
		}
	}
	.p-treetable-footer {
		padding: 0.375rem 0.375rem;
	}
}
.p-treetable.p-treetable-lg {
	.p-treetable-header {
		padding: 0.9375rem 0.9375rem;
	}
	.p-treetable-thead {
		>tr {
			>th {
				padding: 0.9375rem 0.9375rem;
			}
		}
	}
	.p-treetable-tbody {
		>tr {
			>td {
				padding: 0.9375rem 0.9375rem;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				padding: 0.9375rem 0.9375rem;
			}
		}
	}
	.p-treetable-footer {
		padding: 0.9375rem 0.9375rem;
	}
}
.p-timeline {
	.p-timeline-event-marker {
		border: 0 none;
		border-radius: 50%;
		width: 1rem;
		height: 1rem;
		background-color: $background-color_primary;
	}
	.p-timeline-event-connector {
		background-color: $background-color_7;
	}
}
.p-timeline.p-timeline-vertical {
	.p-timeline-event-opposite {
		padding: 0 1rem;
	}
	.p-timeline-event-content {
		padding: 0 1rem;
	}
	.p-timeline-event-connector {
		width: 2px;
	}
}
.p-timeline.p-timeline-horizontal {
	.p-timeline-event-opposite {
		padding: 1rem 0;
	}
	.p-timeline-event-content {
		padding: 1rem 0;
	}
	.p-timeline-event-connector {
		height: 2px;
	}
}
.p-accordion {
	.p-accordion-header {
		.p-accordion-header-link {
			padding: 1rem;
			border: 1px solid #a19f9d;
			color: $color_3;
			background: #faf9f8;
			font-weight: 600;
			border-radius: $borderRadius;
			transition: box-shadow 0.2s;
			.p-accordion-toggle-icon {
				margin-right: 0.5rem;
			}
		}
		&:not(.p-disabled) {
			.p-accordion-header-link {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
		}
		&:not(.p-highlight) {
			&:not(.p-disabled) {
				&:hover {
					.p-accordion-header-link {
						background: #f3f2f1;
						border-color: $border-color_21;
						color: $color_3;
					}
				}
			}
		}
		&:not(.p-disabled).p-highlight {
			.p-accordion-header-link {
				background: #faf9f8;
				border-color: $border-color_21;
				color: $color_3;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
			&:hover {
				.p-accordion-header-link {
					border-color: $border-color_21;
					background: #f3f2f1;
					color: $color_3;
				}
			}
		}
	}
	.p-accordion-content {
		padding: 1rem;
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		border-top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}
	.p-accordion-tab {
		margin-bottom: 4px;
	}
}
.p-card {
	background: #ffffff;
	color: $color_3;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
	border-radius: $borderRadius;
	.p-card-body {
		padding: 1rem;
	}
	.p-card-title {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 0.5rem;
	}
	.p-card-subtitle {
		font-weight: 400;
		margin-bottom: 0.5rem;
		color: $color_2;
	}
	.p-card-content {
		padding: 1rem 0;
	}
	.p-card-footer {
		padding: 1rem 0 0 0;
	}
}
.p-fieldset {
	border: 1px solid #a19f9d;
	background: #ffffff;
	color: $color_3;
	border-radius: $borderRadius;
	.p-fieldset-legend {
		padding: 1rem;
		border: 1px solid #a19f9d;
		color: $color_3;
		background: #faf9f8;
		font-weight: 600;
		border-radius: $borderRadius;
	}
	.p-fieldset-content {
		padding: 1rem;
	}
}
.p-fieldset.p-fieldset-toggleable {
	.p-fieldset-legend {
		padding: 0;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		a {
			padding: 1rem;
			color: $color_3;
			border-radius: $borderRadius;
			transition: box-shadow 0.2s;
			.p-fieldset-toggler {
				margin-right: 0.5rem;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		&:hover {
			background: #f3f2f1;
			border-color: $border-color_21;
			color: $color_3;
		}
	}
}
.p-divider {
	.p-divider-content {
		background-color: $background-color_5;
	}
}
.p-divider.p-divider-horizontal {
	margin: 1rem 0;
	padding: 0 1rem;
	&:before {
		border-top: 1px $border-color_22;
	}
	.p-divider-content {
		padding: 0 0.5rem;
	}
}
.p-divider.p-divider-vertical {
	margin: 0 1rem;
	padding: 1rem 0;
	&:before {
		border-left: 1px $border-color_22;
	}
	.p-divider-content {
		padding: 0.5rem 0;
	}
}
.p-panel {
	.p-panel-header {
		border: 1px solid #a19f9d;
		padding: 1rem;
		background: #faf9f8;
		color: $color_3;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-panel-title {
			font-weight: 600;
		}
		.p-panel-header-icon {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
	.p-panel-content {
		padding: 1rem;
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}
	.p-panel-footer {
		padding: 0.5rem 1rem;
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		border-top: 0 none;
	}
}
.p-panel.p-panel-toggleable {
	.p-panel-header {
		padding: 0.5rem 1rem;
	}
}
.p-splitter {
	border: 1px solid #a19f9d;
	background: #ffffff;
	border-radius: $borderRadius;
	color: $color_3;
	.p-splitter-gutter {
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		background: #faf9f8;
		.p-splitter-gutter-handle {
			background: $background-color_10;
		}
	}
	.p-splitter-gutter-resizing {
		background: $background-color_10;
	}
}
.p-scrollpanel {
	.p-scrollpanel-bar {
		background: #f3f2f1;
		border: 0 none;
	}
}
.p-tabview {
	.p-tabview-nav {
		background: #ffffff;
		border: 0 none;
		border-width: 0 0 2px 0;
		li {
			margin-right: 0;
			.p-tabview-nav-link {
				border: solid #a19f9d;
				border-width: 0 0 2px 0;
				border-color: $border-color_23;
				background: #ffffff;
				color: $color_2;
				padding: 1rem;
				font-weight: 600;
				border-top-right-radius: $borderRadius;
				border-top-left-radius: $borderRadius;
				transition: box-shadow 0.2s;
				margin: 0 0.5rem -2px 0;
				&:not(.p-disabled) {
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: inset inset 0 0 0 1px #605e5c;
					}
				}
			}
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						.p-tabview-nav-link {
							background: #f3f2f1;
							border-color: $border-color_4;
							color: $color_2;
						}
					}
				}
			}
		}
		li.p-highlight {
			.p-tabview-nav-link {
				background: #ffffff;
				border-color: $border-color_2;
				color: $color_3;
			}
		}
	}
	.p-tabview-close {
		margin-left: 0.5rem;
	}
	.p-tabview-nav-btn.p-link {
		background: #ffffff;
		color: $color_3;
		width: 2.357rem;
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
		border-radius: $borderRadius;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset inset 0 0 0 1px #605e5c;
		}
	}
	.p-tabview-panels {
		background: #ffffff;
		padding: 1rem;
		border: 0 none;
		color: $color_3;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}
}
.p-toolbar {
	background: #faf9f8;
	border: 1px solid #a19f9d;
	padding: 1rem;
	border-radius: $borderRadius;
	.p-toolbar-separator {
		margin: 0 0.5rem;
	}
}
.p-confirm-popup {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
	.p-confirm-popup-content {
		padding: 1rem;
	}
	.p-confirm-popup-footer {
		text-align: right;
		padding: 0 1rem 1rem 1rem;
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
			&:last-child {
				margin: 0;
			}
		}
	}
	&:after {
		border: solid transparent;
		border-color: $border-color_24;
		border-bottom-color: $border-bottom-color_1;
	}
	&:before {
		border: solid transparent;
		border-color: $border-color_24;
		border-bottom-color: $border-bottom-color_1;
	}
	.p-confirm-popup-icon {
		font-size: 1.5rem;
	}
	.p-confirm-popup-message {
		margin-left: 1rem;
	}
}
.p-confirm-popup.p-confirm-popup-flipped {
	&:after {
		border-top-color: $border-top-color_1;
	}
	&:before {
		border-top-color: $border-top-color_1;
	}
}

@keyframes zoomChange {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.p-dialog {
	border-radius: $borderRadius $borderRadius 2px 2px;
	animation: 0.5s ease-out 0s 1 zoomChange;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
	border: 0 none;
	.p-dialog-header {
		border-radius: $borderRadius $borderRadius 2px 2px;
		border-bottom: 0 none;
		background: #ffffff;
		color: $color_3;
		padding: 1.5rem;
		.p-dialog-title {
			font-weight: 600;
			font-size: 1.5rem;
		}
		.p-dialog-header-icon {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin-right: 0.5rem;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.p-dialog-content {
		background: #ffffff;
		color: $color_3;
		padding: 0 1.5rem 2rem 1.5rem;
		&:last-of-type {
			border-radius: 2px 2px $borderRadius $borderRadius;
		}
	}
	.p-dialog-footer {
		border-radius: 2px 2px $borderRadius $borderRadius;
		border-top: 0 none;
		background: #ffffff;
		color: $color_3;
		padding: 0 1.5rem 1.5rem 1.5rem;
		text-align: right;
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
		}
	}
}
.p-dialog.p-confirm-dialog {
	.p-confirm-dialog-icon {
		font-size: 2rem;
	}
	.p-confirm-dialog-message {
		margin-left: 1rem;
	}
}
.p-overlaypanel {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
	.p-overlaypanel-content {
		padding: 1rem;
	}
	.p-overlaypanel-close {
		background: $background-color_primary;
		color: $color_primary_text;
		width: 2rem;
		height: 2rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: $borderRadius;
		position: absolute;
		top: -1rem;
		right: -1rem;
		&:enabled {
			&:hover {
				background: #106ebe;
				color: $color_primary_text;
			}
		}
	}
	&:after {
		border: solid transparent;
		border-color: $border-color_24;
		border-bottom-color: $border-bottom-color_1;
	}
	&:before {
		border: solid transparent;
		border-color: $border-color_24;
		border-bottom-color: $border-bottom-color_2;
	}
}
.p-overlaypanel.p-overlaypanel-flipped {
	&:after {
		border-top-color: $border-top-color_1;
	}
	&:before {
		border-top-color: $border-top-color_1;
	}
}
.p-sidebar {
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
	.p-sidebar-header {
		padding: 1rem;
		.p-sidebar-close {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-sidebar-icon {
			width: 2rem;
			height: 2rem;
			color: $color_2;
			border: 0 none;
			background: transparent;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: $color_2;
					border-color: $border-color_4;
					background: #f3f2f1;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		+ {
			.p-sidebar-content {
				padding-top: 0;
			}
		}
	}
	.p-sidebar-content {
		padding: 1rem;
	}
}
.p-tooltip {
	.p-tooltip-text {
		background: #ffffff;
		color: $color_3;
		padding: 0.5rem 0.5rem;
		box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
		border-radius: $borderRadius;
		font-size: 90%;
	}
}
.p-tooltip.p-tooltip-right {
	.p-tooltip-arrow {
		border-right-color: $border-right-color_1;
	}
}
.p-tooltip.p-tooltip-left {
	.p-tooltip-arrow {
		border-left-color: $border-left-color_1;
	}
}
.p-tooltip.p-tooltip-top {
	.p-tooltip-arrow {
		border-top-color: $border-top-color_1;
	}
}
.p-tooltip.p-tooltip-bottom {
	.p-tooltip-arrow {
		border-bottom-color: $border-bottom-color_1;
	}
}
.p-fileupload {
	.p-fileupload-buttonbar {
		background: #faf9f8;
		padding: 1rem;
		border: 1px solid #a19f9d;
		color: $color_3;
		border-bottom: 0 none;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
		.p-button {
			margin-right: 0.5rem;
		}
	}
	.p-fileupload-content {
		background: #ffffff;
		padding: 2rem 1rem;
		border: 1px solid #a19f9d;
		color: $color_3;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}
	.p-progressbar {
		height: 2px;
	}
	.p-fileupload-row {
		>div {
			padding: 0.75rem 0.75rem;
		}
	}
}
.p-fileupload.p-fileupload-advanced {
	.p-message {
		margin-top: 0;
	}
}
.p-breadcrumb {
	background: #ffffff;
	border: 1px solid #eeeeee;
	border-radius: $borderRadius;
	padding: 1rem;
	ul {
		li {
			.p-menuitem-link {
				transition: box-shadow 0.2s;
				border-radius: $borderRadius;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
			}
			&:last-child {
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
			}
		}
		li.p-breadcrumb-chevron {
			margin: 0 0.5rem 0 0.5rem;
			color: $color_3;
		}
	}
}
.p-contextmenu {
	padding: 0;
	background: #ffffff;
	color: $color_3;
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: $borderRadius;
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 0.5rem;
		color: $color_3;
		border-radius: $borderRadius;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: $color_3;
		}
		.p-menuitem-icon {
			color: $color_primary;
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: $color_primary;
		}
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-submenu-list {
		padding: 0;
		background: #ffffff;
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-radius: $borderRadius;
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: $background-color_10;
			.p-menuitem-text {
				color: $color_3;
			}
			.p-menuitem-icon {
				color: $color_primary;
			}
			.p-submenu-icon {
				color: $color_primary;
			}
		}
	}
	.p-menu-separator {
		border-top: 1px solid $border-color_22;
		margin: 0.25rem 0;
	}
	.p-submenu-icon {
		font-size: 0.875rem;
	}
}
.p-dock {
	.p-dock-list {
		background: rgba(255, 255, 255, 0.1);
		border: 1px solid rgba(255, 255, 255, 0.2);
		padding: 0.5rem 0.5rem;
		border-radius: $borderRadius.5rem;
	}
	.p-dock-item {
		padding: 0.5rem;
	}
	.p-dock-action {
		width: 4rem;
		height: 4rem;
	}
}
.p-dock.p-dock-magnification.p-dock-top {
	.p-dock-item-second-prev {
		margin: 0 0.9rem;
	}
	.p-dock-item-second-next {
		margin: 0 0.9rem;
	}
	.p-dock-item-prev {
		margin: 0 1.3rem;
	}
	.p-dock-item-next {
		margin: 0 1.3rem;
	}
	.p-dock-item-current {
		margin: 0 1.5rem;
	}
}
.p-dock.p-dock-magnification.p-dock-bottom {
	.p-dock-item-second-prev {
		margin: 0 0.9rem;
	}
	.p-dock-item-second-next {
		margin: 0 0.9rem;
	}
	.p-dock-item-prev {
		margin: 0 1.3rem;
	}
	.p-dock-item-next {
		margin: 0 1.3rem;
	}
	.p-dock-item-current {
		margin: 0 1.5rem;
	}
}
.p-dock.p-dock-magnification.p-dock-left {
	.p-dock-item-second-prev {
		margin: 0.9rem 0;
	}
	.p-dock-item-second-next {
		margin: 0.9rem 0;
	}
	.p-dock-item-prev {
		margin: 1.3rem 0;
	}
	.p-dock-item-next {
		margin: 1.3rem 0;
	}
	.p-dock-item-current {
		margin: 1.5rem 0;
	}
}
.p-dock.p-dock-magnification.p-dock-right {
	.p-dock-item-second-prev {
		margin: 0.9rem 0;
	}
	.p-dock-item-second-next {
		margin: 0.9rem 0;
	}
	.p-dock-item-prev {
		margin: 1.3rem 0;
	}
	.p-dock-item-next {
		margin: 1.3rem 0;
	}
	.p-dock-item-current {
		margin: 1.5rem 0;
	}
}
.p-megamenu {
	padding: 0.5rem;
	color: $color_primary_text;
	background: $background-color_primary;
	border: 1px solid $background-color_primary;
	border-radius: $borderRadius;
	.p-megamenu-root-list {
		>.p-menuitem {
			>.p-menuitem-link {
				padding: 0.75rem 0.5rem;
				color: #fff;
				border-radius: $borderRadius;
				transition: box-shadow 0.2s;
				user-select: none;
				.p-menuitem-text {
					color: #fff;
				}
				.p-menuitem-icon {
					color: #fff;
					margin-right: 0.5rem;
				}
				.p-submenu-icon {
					color: #fff !important;
					margin-left: 0.5rem;
				}
				&:not(.p-disabled) {
					&:hover {
						background: #f3f2f1;
						.p-menuitem-text {
							color: $color_3;
						}
						.p-menuitem-icon {
							color: $color_primary;
						}
						.p-submenu-icon {
							color: $color_primary;
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
		}
		>.p-menuitem.p-menuitem-active {
			>.p-menuitem-link {
				background: $background-color_10;
				&:not(.p-disabled) {
					&:hover {
						background: $background-color_10;
						.p-menuitem-text {
							color: $color_3;
						}
						.p-menuitem-icon {
							color: $color_primary;
						}
						.p-submenu-icon {
							color: $color_primary;
						}
					}
				}
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
	}
	.p-menuitem-link {
		padding: 0.75rem 0.5rem;
		color: $color_3;
		border-radius: $borderRadius;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: $color_3;
		}
		.p-menuitem-icon {
			color: $color_primary;
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: $color_primary;
		}
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-megamenu-panel {
		background: #ffffff;
		color: $color_3;
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-megamenu-submenu-header {
		margin: 0;
		padding: 0.75rem 0.5rem;
		color: $color_primary;
		background: #ffffff;
		font-weight: 600;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}
	.p-megamenu-submenu {
		padding: 0;
		width: 12.5rem;
		.p-menu-separator {
			border-top: 1px solid $border-color_22;
			margin: 0.25rem 0;
		}
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: $background-color_10;
			.p-menuitem-text {
				color: $color_3;
			}
			.p-menuitem-icon {
				color: $color_primary;
			}
			.p-submenu-icon {
				color: $color_primary;
			}
		}
	}
}
.p-megamenu.p-megamenu-vertical {
	width: 12.5rem;
	padding: 0;
}
.p-menu {
	padding: 0;
	background: #ffffff;
	color: $color_3;
	border: 1px solid #eeeeee;
	border-radius: $borderRadius;
	width: 12.5rem;
	.p-menuitem-link {
		margin : 5px 0 !important;
		padding: 0.75rem 0.5rem;
		color: $color_3;
		border-radius: $borderRadius;
		transition: box-shadow 0.2s;
		user-select: none;
		&.p-menuitem-active {
			border-radius: $borderRadius;
			background: $background-color_10;
			color: var(--primary-color);
			.p-menuitem-text {
				color: #323130;
			}
		}
		.p-menuitem-text {
			color: $color_3;
		}
		.p-menuitem-icon {
			color: $color_primary;
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: $color_primary;
		}
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-submenu-header {
		margin: 0;
		padding: 0.75rem 0.5rem;
		color: $color_primary;
		background: #ffffff;
		font-weight: 600;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	.p-menu-separator {
		border-top: 1px solid $border-color_22;
		margin: 0.25rem 0;
	}
}
.p-menu.p-menu-overlay {
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-menubar {
	padding: 0.5rem;
	background: $background-color_primary;
	color: $color_primary_text;
	border: 1px solid $background-color_primary;
	border-radius: $borderRadius;
	.p-menuitem-link {
		padding: 0.75rem 0.5rem;
		color: $color_primary_text;
		border-radius: $borderRadius;
		transition: box-shadow 0.2s;
		user-select: none;
		&.p-menuitem-active {
			color: $color_primary_text;
			background: var(--bluegray-900);
			border-color: var(--bluegray-800);
		}
		.p-menuitem-text {
			color: $color_3;
		}
		.p-menuitem-icon {
			color: $color_primary;
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: $color_primary;
		}
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-menubar-root-list {
		>.p-menuitem {
			>.p-menuitem-link {
				padding: 0.75rem 0.75rem;
				margin-right: 0.5rem;
				color: $color_primary_text;
				border-radius: $borderRadius;
				transition: box-shadow 0.2s;
				user-select: none;
				.p-menuitem-text {
					color: $color_primary_text;
				}
				.p-menuitem-icon {
					color: $color_primary;
					margin-right: 0.5rem;
				}
				.p-submenu-icon {
					color: $color_primary;
					margin-left: 0.5rem;
				}
				&:not(.p-disabled) {
					&:hover {
						background: #f3f2f1;
						.p-menuitem-text {
							color: $color_3;
						}
						.p-menuitem-icon {
							color: $color_primary;
						}
						.p-submenu-icon {
							color: $color_primary;
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
		}
		>.p-menuitem.p-menuitem-active {
			>.p-menuitem-link {
				background: $background-color_10;
				&:not(.p-disabled) {
					&:hover {
						background: $background-color_10;
						.p-menuitem-text {
							color: $color_3;
						}
						.p-menuitem-icon {
							color: $color_primary;
						}
						.p-submenu-icon {
							color: $color_primary;
						}
					}
				}
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
	}
	.p-submenu-list {
		padding: 0;
		background: #ffffff;
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		width: 12.5rem;
		.p-menu-separator {
			border-top: 1px solid $border-color_22;
			margin: 0.25rem 0;
		}
		.p-submenu-icon {
			font-size: 0.875rem;
		}
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: $background-color_10;
			.p-menuitem-text {
				color: $color_3;
			}
			.p-menuitem-icon {
				color: $color_primary;
			}
			.p-submenu-icon {
				color: $color_primary;
			}
		}
	}
}
.p-panelmenu {
	.p-panelmenu-header {
		>a {
			padding: 1rem;
			border: 1px solid #a19f9d;
			color: $color_3;
			background: #faf9f8;
			font-weight: 600;
			border-radius: $borderRadius;
			transition: box-shadow 0.2s;
			.p-panelmenu-icon {
				margin-right: 0.5rem;
			}
			.p-menuitem-icon {
				margin-right: 0.5rem;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		&:not(.p-highlight) {
			&:not(.p-disabled) {
				>a {
					&:hover {
						background: #f3f2f1;
						border-color: $border-color_21;
						color: $color_3;
					}
				}
			}
		}
	}
	.p-panelmenu-header.p-highlight {
		margin-bottom: 0;
		>a {
			background: #faf9f8;
			border-color: $border-color_21;
			color: $color_3;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:not(.p-disabled) {
			>a {
				&:hover {
					border-color: $border-color_21;
					background: #f3f2f1;
					color: $color_3;
				}
			}
		}
	}
	.p-panelmenu-content {
		padding: 0;
		border: 1px solid #a19f9d;
		background: #ffffff;
		color: $color_3;
		border-top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
		.p-menuitem {
			.p-menuitem-link {
				padding: 0.75rem 0.5rem;
				color: $color_3;
				border-radius: $borderRadius;
				transition: box-shadow 0.2s;
				user-select: none;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
					margin-right: 0.5rem;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
				&:not(.p-disabled) {
					&:hover {
						background: #f3f2f1;
						.p-menuitem-text {
							color: $color_3;
						}
						.p-menuitem-icon {
							color: $color_primary;
						}
						.p-submenu-icon {
							color: $color_primary;
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
				.p-panelmenu-icon {
					margin-right: 0.5rem;
				}
			}
		}
		.p-submenu-list {
			&:not(.p-panelmenu-root-submenu) {
				padding: 0 0 0 1rem;
			}
		}
	}
	.p-panelmenu-panel {
		margin-bottom: 4px;
	}
}
.p-slidemenu {
	padding: 0;
	background: #ffffff;
	color: $color_3;
	border: 1px solid #eeeeee;
	border-radius: $borderRadius;
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 0.5rem;
		color: $color_3;
		border-radius: $borderRadius;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: $color_3;
		}
		.p-menuitem-icon {
			color: $color_primary;
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: $color_primary;
		}
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-slidemenu-list {
		padding: 0;
		background: #ffffff;
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-slidemenu.p-slidemenu-active {
		>.p-slidemenu-link {
			background: $background-color_10;
			.p-slidemenu-text {
				color: $color_3;
			}
			.p-slidemenu-icon {
				color: $color_primary;
				color: $color_primary;
			}
		}
	}
	.p-slidemenu-separator {
		border-top: 1px solid $border-color_22;
		margin: 0.25rem 0;
	}
	.p-slidemenu-icon {
		font-size: 0.875rem;
	}
	.p-slidemenu-backward {
		padding: 0.75rem 0.5rem;
		color: $color_3;
	}
}
.p-slidemenu.p-slidemenu-overlay {
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-steps {
	.p-steps-item {
		.p-menuitem-link {
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: $borderRadius;
			background: #ffffff;
			.p-steps-number {
				color: $color_3;
				border: 1px solid #f3f2f1;
				background: #ffffff;
				min-width: 2rem;
				height: 2rem;
				line-height: 2rem;
				font-size: 1.143rem;
				z-index: 1;
				border-radius: 50%;
			}
			.p-steps-title {
				margin-top: 0.5rem;
				color: $color_2;
			}
			&:not(.p-disabled) {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: $boxShadow;
				}
			}
		}
		&:before {
			content: " ";
			border-top: 1px solid $border-color_22;
			width: 100%;
			top: 50%;
			left: 0;
			display: block;
			position: absolute;
			margin-top: -1rem;
		}
	}
	.p-steps-item.p-highlight {
		.p-steps-number {
			background: $background-color_10;
			color: $color_3;
		}
		.p-steps-title {
			font-weight: 600;
			color: $color_3;
		}
	}
}
.p-tabmenu {
	.p-tabmenu-nav {
		background: #ffffff;
		border: 0 none;
		border-width: 0 0 2px 0;
		.p-tabmenuitem {
			margin-right: 0;
			.p-menuitem-link {
				border: solid #a19f9d;
				border-width: 0 0 2px 0;
				border-color: $border-color_23;
				background: #ffffff;
				color: $color_2;
				padding: 1rem;
				font-weight: 600;
				border-top-right-radius: $borderRadius;
				border-top-left-radius: $borderRadius;
				transition: box-shadow 0.2s;
				margin: 0 0.5rem -2px 0;
				height: calc(100% + 2px);
				.p-menuitem-icon {
					margin-right: 0.5rem;
				}
				&:not(.p-disabled) {
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: inset inset 0 0 0 1px #605e5c;
					}
				}
			}
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						.p-menuitem-link {
							background: #f3f2f1;
							border-color: $border-color_4;
							color: $color_2;
						}
					}
				}
			}
		}
		.p-tabmenuitem.p-highlight {
			.p-menuitem-link {
				background: #ffffff;
				border-color: $border-color_2;
				color: $color_3;
			}
		}
	}
}
.p-tieredmenu {
	padding: 0;
	background: #ffffff;
	color: $color_3;
	border: 1px solid #eeeeee;
	border-radius: $borderRadius;
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 0.5rem;
		color: $color_3;
		border-radius: $borderRadius;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: $color_3;
		}
		.p-menuitem-icon {
			color: $color_primary;
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: $color_primary;
		}
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				.p-menuitem-text {
					color: $color_3;
				}
				.p-menuitem-icon {
					color: $color_primary;
				}
				.p-submenu-icon {
					color: $color_primary;
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
	.p-submenu-list {
		padding: 0;
		background: #ffffff;
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: $background-color_10;
			.p-menuitem-text {
				color: $color_3;
			}
			.p-menuitem-icon {
				color: $color_primary;
			}
			.p-submenu-icon {
				color: $color_primary;
			}
		}
	}
	.p-menu-separator {
		border-top: 1px solid $border-color_22;
		margin: 0.25rem 0;
	}
	.p-submenu-icon {
		font-size: 0.875rem;
	}
}
.p-tieredmenu.p-tieredmenu-overlay {
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-inline-message {
	padding: 0.5rem 0.5rem;
	margin: 0;
	border-radius: $borderRadius;
	.p-inline-message-icon {
		font-size: 1rem;
		margin-right: 0.5rem;
	}
	.p-inline-message-text {
		font-size: 90%;
	}
}
.p-inline-message.p-inline-message-info {
	background: #f3f2f1;
	border: 0 none;
	border-width: 0px;
	color: $color_3;
	.p-inline-message-icon {
		color: $color_2;
	}
}
.p-inline-message.p-inline-message-success {
	background: #dff6dd;
	border: 0 none;
	border-width: 0px;
	color: $color_3;
	.p-inline-message-icon {
		color: $color_15;
	}
}
.p-inline-message.p-inline-message-warn {
	background: #fff4ce;
	border: 0 none;
	border-width: 0px;
	color: $color_3;
	.p-inline-message-icon {
		color: $color_16;
	}
}
.p-inline-message.p-inline-message-error {
	background: #fde7e9;
	border: 0 none;
	border-width: 0px;
	color: $color_3;
	.p-inline-message-icon {
		color: $color_17;
	}
}
.p-inline-message.p-inline-message-icon-only {
	.p-inline-message-icon {
		margin-right: 0;
	}
}
.p-message {
	margin: 1rem 0;
	border-radius: $borderRadius;
	font-size: 90%;
	.p-message-wrapper {
		padding: 0.5rem;
	}
	.p-message-close {
		width: 2rem;
		height: 2rem;
		border-radius: $borderRadius;
		background: transparent;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:hover {
			background: rgba(255, 255, 255, 0.3);
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
		.p-message-close-icon {
			font-size: 90%;
		}
	}
	.p-message-text {
		font-size: 90%;
		font-weight: 500;
	}
	.p-message-icon {
		font-size: 1rem;
		margin-right: 0.5rem;
	}
}
.p-message.p-message-info {
	background: #f3f2f1;
	border: 0 none;
	border-width: 0 0 0 6px;
	color: $color_3;
	.p-message-icon {
		color: $color_2;
	}
	.p-message-close {
		color: $color_2;
	}
}
.p-message.p-message-success {
	background: #dff6dd;
	border: 0 none;
	border-width: 0 0 0 6px;
	color: $color_3;
	.p-message-icon {
		color: $color_15;
	}
	.p-message-close {
		color: $color_15;
	}
}
.p-message.p-message-warn {
	background: #fff4ce;
	border: 0 none;
	border-width: 0 0 0 6px;
	color: $color_3;
	.p-message-icon {
		color: $color_16;
	}
	.p-message-close {
		color: $color_16;
	}
}
.p-message.p-message-error {
	background: #fde7e9;
	border: 0 none;
	border-width: 0 0 0 6px;
	color: $color_3;
	.p-message-icon {
		color: $color_17;
	}
	.p-message-close {
		color: $color_17;
	}
}
.p-toast {
	opacity: 0.9;
	font-size: 90%;
	.p-toast-message {
		margin: 0 0 1rem 0;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-radius: $borderRadius;
		.p-toast-message-content {
			padding: 0.5rem;
			border-width: 0;
			.p-toast-message-text {
				margin: 0 0 0 1rem;
			}
			.p-toast-message-icon {
				font-size: 1.25rem;
			}
			.p-toast-summary {
				font-weight: 600;
			}
			.p-toast-detail {
				margin: 0.5rem 0 0 0;
			}
		}
		.p-toast-icon-close {
			width: 1.25rem;
			height: 1.25rem;
			border-radius: $borderRadius;
			background: transparent;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				background: rgba(255, 255, 255, 0.3);
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
	.p-toast-message.p-toast-message-info {
		background: #f3f2f1;
		border: 0 none;
		border-width: 0 0 0 6px;
		color: $color_3;
		.p-toast-message-icon {
			color: $color_2;
		}
		.p-toast-icon-close {
			color: $color_2;
		}
	}
	.p-toast-message.p-toast-message-success {
		background: #dff6dd;
		border: 0 none;
		border-width: 0 0 0 6px;
		color: $color_3;
		.p-toast-message-icon {
			color: $color_15;
		}
		.p-toast-icon-close {
			color: $color_15;
		}
	}
	.p-toast-message.p-toast-message-warn {
		background: #fff4ce;
		border: 0 none;
		border-width: 0 0 0 6px;
		color: $color_3;
		.p-toast-message-icon {
			color: $color_16;
		}
		.p-toast-icon-close {
			color: $color_16;
		}
	}
	.p-toast-message.p-toast-message-error {
		background: #fde7e9;
		border: 0 none;
		border-width: 0 0 0 6px;
		color: $color_3;
		.p-toast-message-icon {
			color: $color_17;
		}
		.p-toast-icon-close {
			color: $color_17;
		}
	}
	.p-toast-icon-close-icon {
		font-size: 90%;
	}
}
.p-galleria {
	.p-galleria-close {
		margin: 0.5rem;
		background: transparent;
		color: $color_18;
		width: 4rem;
		height: 4rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		.p-galleria-close-icon {
			font-size: 2rem;
		}
		&:hover {
			background: rgba(255, 255, 255, 0.1);
			color: $color_18;
		}
	}
	.p-galleria-item-nav {
		background: transparent;
		color: $color_18;
		width: 4rem;
		height: 4rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: $borderRadius;
		margin: 0 0.5rem;
		.p-galleria-item-prev-icon {
			font-size: 2rem;
		}
		.p-galleria-item-next-icon {
			font-size: 2rem;
		}
		&:not(.p-disabled) {
			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: $color_18;
			}
		}
	}
	.p-galleria-caption {
		background: rgba(0, 0, 0, 0.5);
		color: $color_18;
		padding: 1rem;
	}
	.p-galleria-indicators {
		padding: 1rem;
		.p-galleria-indicator {
			button {
				background-color: $background-color_6;
				width: 1rem;
				height: 1rem;
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				border-radius: 50%;
				&:hover {
					background: #e1dfdd;
				}
			}
		}
		.p-galleria-indicator.p-highlight {
			button {
				background: $background-color_10;
				color: $color_3;
				background-color: $background-color_primary;
			}
		}
	}
	.p-galleria-thumbnail-container {
		background: rgba(0, 0, 0, 0.9);
		padding: 1rem 0.25rem;
		.p-galleria-thumbnail-prev {
			margin: 0.5rem;
			background-color: $background-color_3;
			color: $color_18;
			width: 2rem;
			height: 2rem;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			border-radius: 50%;
			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: $color_18;
			}
		}
		.p-galleria-thumbnail-next {
			margin: 0.5rem;
			background-color: $background-color_3;
			color: $color_18;
			width: 2rem;
			height: 2rem;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			border-radius: 50%;
			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: $color_18;
			}
		}
		.p-galleria-thumbnail-item-content {
			transition: box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
	}
}
.p-galleria.p-galleria-indicators-bottom {
	.p-galleria-indicator {
		margin-right: 0.5rem;
	}
}
.p-galleria.p-galleria-indicators-top {
	.p-galleria-indicator {
		margin-right: 0.5rem;
	}
}
.p-galleria.p-galleria-indicators-left {
	.p-galleria-indicator {
		margin-bottom: 0.5rem;
	}
}
.p-galleria.p-galleria-indicators-right {
	.p-galleria-indicator {
		margin-bottom: 0.5rem;
	}
}
.p-galleria.p-galleria-indicator-onitem {
	.p-galleria-indicators {
		background: rgba(0, 0, 0, 0.5);
		.p-galleria-indicator {
			button {
				background: rgba(255, 255, 255, 0.4);
				&:hover {
					background: rgba(255, 255, 255, 0.6);
				}
			}
		}
		.p-galleria-indicator.p-highlight {
			button {
				background: $background-color_10;
				color: $color_3;
			}
		}
	}
}
.p-galleria-mask {
	--maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-mask {
	--maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-preview-indicator {
	background-color: $background-color_3;
	color: $color_19;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-image-preview-container {
	&:hover {
		>.p-image-preview-indicator {
			background-color: $background-color_8;
		}
	}
}
.p-image-toolbar {
	padding: 1rem;
}
.p-image-action.p-link {
	color: $color_19;
	background-color: $background-color_3;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	margin-right: 0.5rem;
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		color: $color_19;
		background-color: $background-color_9;
	}
	i {
		font-size: 1.5rem;
	}
}
.p-avatar {
	background-color: $background-color_10;
	border-radius: $borderRadius;
}
.p-avatar.p-avatar-lg {
	width: 3rem;
	height: 3rem;
	font-size: 1.5rem;
	.p-avatar-icon {
		font-size: 1.5rem;
	}
}
.p-avatar.p-avatar-xl {
	width: 4rem;
	height: 4rem;
	font-size: 2rem;
	.p-avatar-icon {
		font-size: 2rem;
	}
}
.p-avatar-group {
	.p-avatar {
		border: 2px solid #ffffff;
	}
}
.p-chip {
	background-color: $background-color_10;
	color: $color_3;
	border-radius: $borderRadius;
	padding: 0 0.5rem;
	.p-chip-text {
		line-height: 1.5;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}
	.p-chip-icon {
		margin-right: 0.5rem;
	}
	img {
		width: 2rem;
		height: 2rem;
		margin-left: -0.5rem;
		margin-right: 0.5rem;
	}
	.p-chip-remove-icon {
		border-radius: $borderRadius;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		margin-left: 0.5rem;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
}
.p-scrolltop {
	width: 3rem;
	height: 3rem;
	border-radius: $borderRadius;
	box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	.p-scrolltop-icon {
		font-size: 1.5rem;
		color: $color_18;
	}
}
.p-scrolltop.p-link {
	background: rgba(0, 0, 0, 0.7);
	&:hover {
		background: rgba(0, 0, 0, 0.8);
	}
}
.p-skeleton {
	background-color: $background-color_10;
	border-radius: $borderRadius;
	&:after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
	}
}
.p-tag {
	background: $background-color_primary;
	color: $color_primary_text;
	font-size: 0.75rem;
	font-weight: 700;
	padding: 0.25rem 0.4rem;
	border-radius: $borderRadius;
	background: $background-color_primary;
	color: $color_primary_text;
	font-size: 0.75rem;
	font-weight: 700;
	padding: 0.25rem 0.4rem;
	border-radius: $borderRadius;
	.p-tag-icon {
		margin-right: 0.25rem;
		font-size: 0.75rem;
	}
}
.p-tag.p-tag-success {
	background-color: $background-color_11;
	color: $color_primary_text;
	background-color: $background-color_11;
	color: $color_primary_text;
}
.p-tag.p-tag-info {
	background-color: $background-color_12;
	color: $color_primary_text;
	background-color: $background-color_12;
	color: $color_primary_text;
}
.p-tag.p-tag-warning {
	background-color: $background-color_13;
	color: $color_3;
	background-color: $background-color_13;
	color: $color_3;
}
.p-tag.p-tag-danger {
	background-color: $background-color_14;
	color: $color_primary_text;
	background-color: $background-color_14;
	color: $color_primary_text;
}
.p-inplace {
	.p-inplace-display {
		padding: 0.5rem 0.5rem;
		border-radius: $borderRadius;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:not(.p-disabled) {
			&:hover {
				background: #f3f2f1;
				color: $color_3;
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: $boxShadow;
		}
	}
}
.p-progressbar {
	border: 0 none;
	height: 2px;
	background: $background-color_10;
	border-radius: $borderRadius;
	.p-progressbar-value {
		border: 0 none;
		margin: 0;
		background: $background-color_primary;
	}
	.p-progressbar-label {
		color: $color_primary_text;
		line-height: 2px;
	}
}
.p-terminal {
	background: #ffffff;
	color: $color_3;
	border: 1px solid #a19f9d;
	padding: 1rem;
	.p-terminal-input {
		font-size: 1rem;
		font-family: $font-family_1;
	}
}
.p-badge {
	background: $background-color_primary;
	color: $color_primary_text;
	font-size: 0.75rem;
	font-weight: 700;
	min-width: 1.5rem;
	height: 1.5rem;
	line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
	background-color: $background-color_15;
	color: $color_primary_text;
}
.p-badge.p-badge-success {
	background-color: $background-color_11;
	color: $color_primary_text;
}
.p-badge.p-badge-info {
	background-color: $background-color_12;
	color: $color_primary_text;
}
.p-badge.p-badge-warning {
	background-color: $background-color_13;
	color: $color_3;
}
.p-badge.p-badge-danger {
	background-color: $background-color_14;
	color: $color_primary_text;
}
.p-badge.p-badge-lg {
	font-size: 1.125rem;
	min-width: 2.25rem;
	height: 2.25rem;
	line-height: 2.25rem;
}
.p-badge.p-badge-xl {
	font-size: 1.5rem;
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}
.p-button-label {
	font-weight: 600;
}
.p-submenu-icon {
	color: $color_2 !important;
}
.p-menuitem-active {
	.p-submenu-icon {
		color: $color_3 !important;
	}
}
.p-progressbar-label {
	display: none !important;
}
.p-autocomplete.p-autocomplete-multiple {
	.p-autocomplete-multiple-container.p-disabled {
		background-color: $background-color_18;
		border-color: $border-color_26;
		color: $color_20;
		opacity: 1;
		user-select: none;
	}
	.p-autocomplete-multiple-container {
		&:not(.p-disabled).p-focus {
			box-shadow: inset 0 0 0 1px $background-color_primary;
		}
	}
}
.p-dropdown.p-disabled {
	background-color: $background-color_18;
	border-color: $border-color_26;
	color: $color_20;
	opacity: 1;
	user-select: none;
	.p-dropdown-label {
		color: $color_20;
	}
	.p-dropdown-trigger-icon {
		color: $color_20;
	}
}
.p-multiselect.p-disabled {
	background-color: $background-color_18;
	border-color: $border-color_26;
	color: $color_20;
	opacity: 1;
	user-select: none;
	.p-dropdown-label {
		color: $color_20;
	}
	.p-dropdown-trigger-icon {
		color: $color_20;
	}
}
@media screen and (max-width: 769px) {
	.p-datepicker {
		table {
			th {
				padding: 0;
			}
			td {
				padding: 0;
			}
		}
	}
	.p-orderlist {
		flex-direction: column;
		.p-orderlist-controls {
			padding: 1rem;
			flex-direction: row;
			.p-button {
				margin-right: 0.5rem;
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.p-picklist {
		flex-direction: column;
		.p-picklist-buttons {
			padding: 1rem;
			flex-direction: row;
			.p-button {
				margin-right: 0.5rem;
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.p-picklist-transfer-buttons {
			.pi-angle-right {
				&:before {
					content: "\e930";
				}
			}
			.pi-angle-double-right {
				&:before {
					content: "\e92c";
				}
			}
			.pi-angle-left {
				&:before {
					content: "\e933";
				}
			}
			.pi-angle-double-left {
				&:before {
					content: "\e92f";
				}
			}
		}
	}
}
@media screen and (max-width: 960px) {
	.p-dock.p-dock-top {
		.p-dock-container {
			overflow-x: auto;
			width: 100%;
			.p-dock-list {
				margin: 0 auto;
			}
		}
	}
	.p-dock.p-dock-bottom {
		.p-dock-container {
			overflow-x: auto;
			width: 100%;
			.p-dock-list {
				margin: 0 auto;
			}
		}
	}
	.p-dock.p-dock-left {
		.p-dock-container {
			overflow-y: auto;
			height: 100%;
			.p-dock-list {
				margin: auto 0;
			}
		}
	}
	.p-dock.p-dock-right {
		.p-dock-container {
			overflow-y: auto;
			height: 100%;
			.p-dock-list {
				margin: auto 0;
			}
		}
	}
	.p-dock.p-dock-magnification.p-dock-top {
		.p-dock-item-second-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-second-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-current {
			transform: none;
			margin: 0;
		}
	}
	.p-dock.p-dock-magnification.p-dock-bottom {
		.p-dock-item-second-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-second-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-current {
			transform: none;
			margin: 0;
		}
	}
	.p-dock.p-dock-magnification.p-dock-left {
		.p-dock-item-second-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-second-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-current {
			transform: none;
			margin: 0;
		}
	}
	.p-dock.p-dock-magnification.p-dock-right {
		.p-dock-item-second-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-second-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-prev {
			transform: none;
			margin: 0;
		}
		.p-dock-item-next {
			transform: none;
			margin: 0;
		}
		.p-dock-item-current {
			transform: none;
			margin: 0;
		}
	}
	.p-menubar {
		position: relative;
		.p-menubar-button {
			display: flex;
			width: 2rem;
			height: 2rem;
			color: $color_primary_text;
			border-radius: $borderRadius;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				color: $color_primary_text;
				background: $background-color_primary;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: $boxShadow;
			}
		}
		.p-menubar-root-list {
			position: absolute;
			display: none;
			padding: 0;
			background: #ffffff;
			border: 0 none;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
			width: 100%;
			.p-menu-separator {
				border-top: 1px solid $border-color_22;
				margin: 0.25rem 0;
			}
			.p-submenu-icon {
				font-size: 0.875rem;
			}
			>.p-menuitem {
				width: 100%;
				position: static;
				>.p-menuitem-link {
					padding: 0.75rem 0.5rem;
					color: $color_3;
					border-radius: $borderRadius;
					transition: box-shadow 0.2s;
					user-select: none;
					&.p-menuitem-active {
						background-color: $background-color_10;
						color: $color_primary_text;
						.p-menuitem-text {
							color: $color_primary;
						}
					}
					.p-menuitem-text {
						color: $color_3;
					}
					.p-menuitem-icon {
						color: $color_primary;
						margin-right: 0.5rem;
					}
					.p-submenu-icon {
						color: $color_primary;
					}
					&:not(.p-disabled) {
						&:hover {
							background: #f3f2f1;
							.p-menuitem-text {
								color: $color_3;
							}
							.p-menuitem-icon {
								color: $color_primary;
							}
							.p-submenu-icon {
								color: $color_primary;
							}
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: $boxShadow;
					}
					>.p-submenu-icon {
						margin-left: auto;
						transition: transform 0.2s;
					}
				}
			}
			>.p-menuitem.p-menuitem-active {
				>.p-menuitem-link {
					>.p-submenu-icon {
						transform: rotate(-180deg);
					}
				}
			}
			.p-submenu-list {
				width: 100%;
				position: static;
				box-shadow: none;
				border: 0 none;
				.p-submenu-icon {
					transition: transform 0.2s;
					transform: rotate(90deg);
				}
				.p-menuitem-active {
					>.p-menuitem-link {
						>.p-submenu-icon {
							transform: rotate(-90deg);
						}
					}
				}
			}
			.p-menuitem {
				width: 100%;
				position: static;
			}
			ul {
				li {
					a {
						padding-left: 2.25rem;
					}
					ul {
						li {
							a {
								padding-left: 3.75rem;
							}
							ul {
								li {
									a {
										padding-left: 5.25rem;
									}
									ul {
										li {
											a {
												padding-left: 6.75rem;
											}
											ul {
												li {
													a {
														padding-left: 8.25rem;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.p-menubar.p-menubar-mobile-active {
		.p-menubar-root-list {
			display: flex;
			flex-direction: column;
			top: 100%;
			left: 0;
			z-index: 1;
		}
	}
}
