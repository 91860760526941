.project-grid .p-panel-header {
    .p-panel-title {
        font-weight: 900 !important;
        font-size: large !important;
    }

    padding: 0.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    padding: 0 !important;
}