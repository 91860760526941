.module-layout {
    .p-blockui-container {
        height: 100%;
    }
    .p-toggleable-content {
        .p-panel-content {
            height: 100%;

            .p-scrollpanel {
                height: 100%;
            }
        }

        height: calc(100% - 10px);
    }

    .new-table-action-btn {
        top: 10px;
        position: absolute;
    }
}

.new-table-height {
    height: calc(100% - 55px);
}

.action-cell-class {
    outline: none !important;
}