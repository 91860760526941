
.cellCopied {
  background-color: #ccccff;
}


.cellDraggedOver{
  background-color: #ccccff;

  &.cellCopied {
    background-color: #9999ff;
  }
}

.dg-cell.disabled {
  background-color: lightgray !important;
}

.dg-cell {
  /*
  Cannot use these because of a Chromium bug:
  https://bugs.chromium.org/p/chromium/issues/detail?id=1326946
  once this is fixed we can also remove "position: relative:"
  contain: strict;
  contain: size layout style paint;
  */
  position: relative; /* needed for absolute positioning to work */
  contain: size style;
  padding-block: 0;
  padding-inline: 8px;
  border-inline-end: 1px solid var(--rdg-border-color);
  border-block-end: 1px solid var(--rdg-border-color);
  grid-row-start: var(--rdg-grid-row-start);
  background-color: inherit;

  white-space: nowrap;
  overflow: hidden;
  overflow: clip;
  text-overflow: ellipsis;
  outline: none;

  &[aria-selected='true'] {
    outline: 2px solid var(--rdg-selection-color);
    outline-offset: -2px;
  }
}

.cellFrozen {
  position: sticky;
  /* Should have a higher value than 0 to show up above unfrozen cells */
  z-index: 1;
}

.cellFrozenLast {
  box-shadow: calc(2px * var(--rdg-sign)) 0 5px -2px rgba(136, 136, 136, 0.3);
}

.dg-row {
  display: contents;
  line-height: var(--rdg-row-height);
  background-color: var(--rdg-background-color);

  &:hover {
    background-color: var(--rdg-row-hover-background-color);
  }

  &[aria-selected='true'] {
    background-color: var(--rdg-row-selected-background-color);

    &:hover {
      background-color: var(--row-selected-hover-background-color);
    }
  }
}